//imports-start
/// <reference path="../definitions.d.ts"  />
//imports-end

module Utils.DateTime {
    export function IsValid(date: Date): boolean {
        return date instanceof Date && !isNaN(date.getTime());
    }

    function dateEquals(date1: Date, date2: Date): boolean {
        return date1.getDate() === date2.getDate() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getFullYear() === date2.getFullYear();
    }

    export function Compare(dateA: Date | string, dateB: Date | string): number {
        if (dateA === dateB) {
            return 0;
        }

        if (!dateA && dateB) {
            return 1;
        }

        if (dateA && !dateB) {
            return -1;
        }

        if (typeof dateA === 'string') {
            dateA = new Date(dateA);
        }

        if (typeof dateB === 'string') {
            dateB = new Date(dateB);
        }

        return dateA.getTime() - dateB.getTime();
    }

    export function TimeToString(date: Date): string {
        if (IsValid(date)) {
            return date.format(i18next.t('DateTime.TimeFormat'));
        }
    }

    export function DateToString(date: Date, forceLongDate?: boolean, hideDayAbbreviation?: boolean): string {
        if (!IsValid(date)) {
            return null;
        }

        if (!forceLongDate) {
            const now = new Date();
            if (dateEquals(now, date)) {
                now.setDate(now.getDate());
            } else {
                now.setDate(now.getDate() - 1);
            }
        }

        var dayDateDelimiter = i18next.t('DateTime.DayDateDelimiter') || '';

        if (!!dayDateDelimiter) {
            dayDateDelimiter += ' ';
        }

        return (!hideDayAbbreviation ? getAbbreviationForDayOfWeek(date.getDay()) + dayDateDelimiter : '') +
            date.format(i18next.t('DateTime.DateFormat'));
    }

    export function DateToShortString(date: Date): string {
        if (!IsValid(date)) {
            return null;
        }

        const now = new Date();
        if (dateEquals(now, date)) {
            now.setDate(now.getDate());
        } else {
            now.setDate(now.getDate() - 1);
        }

        return date.format(i18next.t('DateTime.ShortDateFormat'));
    }

    export function DateToMonthAndYearString(date: Date): string {
        if (IsValid(date)) {
            return date.format(i18next.t('DateTime.MonthAndYearFormat'));
        }
    }

    function getAbbreviationForDayOfWeek(dayOfWeek: number): string {
        const daysShort = i18next.t('DateTime.DaysShort');
        const firstDayOfWeek = parseInt(i18next.t('DateTime.FirstDayOfWeek'), 10);

        if (firstDayOfWeek) {
            if (dayOfWeek >= 1 && dayOfWeek <= 6) {
                return daysShort[dayOfWeek - 1];
            } else if (dayOfWeek === 0) {
                return daysShort[6];
            }
        }

        return daysShort[dayOfWeek];
    }

    export function ToGMTString(date: Date | string, suppressTime = false): string {
        const workDate = typeof date == 'string' ? new Date(date) : date;

        if (!IsValid(workDate)) {
            return null;
        }

        const value = workDate.toGMTString().replace(/^(\S{3},\W)(\d?)(\d\W\S{3}\W\d{4}\W\d{2}:\d{2}:\d{2}\W)UTC$/, function() {
            const dateTimePart = Array.prototype.slice.call(arguments, 1, -2);
            dateTimePart.push('GMT');

            if (!dateTimePart[1]) {
                dateTimePart[1] = '0';
            }

            return dateTimePart.join('');
        });

        return suppressTime ? value.substring(0, value.length - 13) : value;
    }

    export function ToISOString(date: Date | string, suppressTime = false): string {
        const workDate = typeof date == 'string' ? new Date(date) : date;

        if (!IsValid(workDate)) {
            return null;
        }

        const value = workDate.toISOString();

        return suppressTime ? value.split('T')[0] : value;
    }

    export function ToString(datetime: Date | string, forceLongDate?: boolean, hideDayAbbreviation?: boolean): string {
        if (typeof datetime === 'string') {
            datetime = new Date(datetime);
        }

        if (IsValid(datetime)) {
            return `${DateToString(datetime, forceLongDate, hideDayAbbreviation)} - ${TimeToString(datetime)}`;
        }

        return '';
    };

    export function GetMonthTitle(date: Date): string {
        if (IsValid(date)) {
            const monthLong = i18next.t('DateTime.MonthLong');
            return monthLong[date.getMonth()];
        }
    };

    export function SetDate(date: Date, day: number, month: number, year: number): Date {
        if (!IsValid(date) || !day || !month || !year) {
            return null;
        }

        date.setDate(day);
        date.setMonth(month - 1);
        date.setFullYear(year);

        return date;
    };

    export function IsTimeOfDateNull(date: Date): boolean {
        return (date.getHours() + date.getMinutes() + date.getSeconds()) === 0;
    }

    export function GetDateString(date: Date): string {
        // 1.8.2019 => 01.08.2019
        return ('0' + date.getDate()).slice(-2) + '.' + ('0' + (date.getMonth() + 1)).slice(-2) + '.' + date.getFullYear();
    }
}