//imports-start
//// <reference path="../definitions.d.ts"  />
//// <reference path="../templates.d.ts"  />
/// <reference path="../model/menu/imenu-item-config.ts" />
/// <reference path="../model/menu/menu-item.ts" />
/// <reference path="../dal/properties.ts"  />
//// <reference path="../dal/scheduling.ts"  />
//// <reference path="../utils/utils.spinner.ts"  />
//// <reference path="../utils/utils.message.ts"  />
//// <reference path="../model/model.tree.ts"  />
//// <reference path="./app.bluetooth-configuration.ts"  />
/// <reference path="../utils/utils.router.ts"  />
//// <reference path="../model/menu/menu-item.ts"  />
/// <reference path="app.parameter-list.ts" />
/// <reference path="../model/selection-window/options.ts" />
/// <reference path="../model/selection-window/form-options.ts" />
/// <reference path="../utils/selection-window/utils.form-selection-window.ts" />
/// <reference path="../utils/selection-window/utils.scheduling-selection-window.ts" />
//imports-end

module Menu {
    let menuItems: Dictionary<Model.Menu.MenuItem> = {};
    let menuItemsConfig: Array<Model.Menu.IMenuItemConfig> = [];
    let visibleMenuItems: Array<Model.Menu.MenuItem> = [];
    let menuItemsWithPreview: Array<Model.Menu.MenuItem> = [];
    let currentPreviewItemIdx: number = 0;
    let lastPreviewItemID: string = null;

    let $content, $preview;
    let $backButton = $('.back-button');

    let currentIssue: Model.Issues.Issue;

    function onLocationFormItemClick(id?: string): void {
        const menuItem = Menu.GetMenuItemConfig(id);
        const userCanSeeAllForms = Utils.UserHasRight(
            Session.User.OID,
            Enums.Rights.SeeAllForms,
            true,
            Session.CurrentLocation
        );

        const showLocationAssignedFormsOnly = Session.Settings.ShowOnlyAssignedForms || !userCanSeeAllForms;
        const options: Model.SelectionWindow.FormOptions = <Model.SelectionWindow.FormOptions>{
            Filter: Model.SelectionWindow.Filter.FromMenuItemConfiguration(menuItem),
            DisableEmptyForms: true,
            ShowInspections: Session.Client.Licenses.Inspections,
            ShowRegularForms: true,
            ShowLocationAssignedFormsOnly: showLocationAssignedFormsOnly,
            ExitIfNoItemIsAvailable: !userCanSeeAllForms,
            ShowDetails: true,
            OnAfterItemsSelected: (selectedIdentifiers: string[]) => {
                if (!selectedIdentifiers.length) {
                    return;
                }

                const formIdentifier = selectedIdentifiers[0];
                const form = DAL.Elements.GetByOID(formIdentifier);

                if (!form) {
                    return;
                }

                const defaultStateIdentifier = form.InitialStateOID || Session.Client.Settings.FormOpened;

                if (!Utils.IsUserAbleToSetInitialState(Session.CurrentLocation.OID, defaultStateIdentifier)) {
                    Utils.Message.Show(i18next.t('Misc.RightError.MessageHeader'),
                        i18next.t('Misc.RightError.DefaultState.MessageBody'),
                        {
                            Close: {}
                        });

                    return;
                }

                Utils.IssueViewer.CreateFormIssue(formIdentifier, (formIssue: Model.Issues.Issue) => {
                    Utils.Spinner.Show();
                    Utils.Router.PushState(`issue/${formIssue.ID || formIssue.OID}?type=${formIssue.Type}`);
                });
            },
            OnOnlySingleApplicableItemExists: function(form: Model.Elements.Element) {
                if (!form) {
                    return;
                }

                const options: Utils.Message.OptionButtons = {
                    Yes: () => {
                        this.selectedItemIdentifiers = [form.OID];
                        this.options.OnAfterItemsSelected(this.selectedItemIdentifiers);
                    },
                    No: true
                };

                if (userCanSeeAllForms) {
                    options.Close = {
                        Caption: i18next.t('SelectionWindow.Forms.SingleApplicableItem.OpenSelection'),
                        Fn: () => {
                            // Auswahlfenster öffnen
                            this.fillWindow(false);
                        },
                        Classes: ['btn', 'flat', 'left-aligned']
                    }
                };

                Utils.Message.Show(i18next.t('SelectionWindow.Forms.SingleApplicableItem.MessageHeader'),
                    i18next.t('SelectionWindow.Forms.SingleApplicableItem.MessageBody', {
                        ApplicableItemTitle: form.Title
                    }), options, null, this.options.ZIndex + 50);
            }
        };

        if (userCanSeeAllForms) {
            const hoverTitle = showLocationAssignedFormsOnly ?
                i18next.t('SelectionWindow.Forms.HierarchyFilter.ShowAllForms') :
                i18next.t('SelectionWindow.Forms.HierarchyFilter.ShowAssignedFormsOnly');

            options.HierarchyFilter = {
                Icon: 'icon-clipboard',
                IsActive: showLocationAssignedFormsOnly,
                HoverTitle: hoverTitle,
                OnClick: function(evt: Event): void {
                    const $buttons = $(evt.currentTarget).parents('.item-tree').find('.btn-hierarchy-filter');

                    this.showLocationAssignedFormsOnly = !this.showLocationAssignedFormsOnly;

                    const newHoverTitle = this.showLocationAssignedFormsOnly ?
                        i18next.t('SelectionWindow.Forms.HierarchyFilter.ShowAllForms') :
                        i18next.t('SelectionWindow.Forms.HierarchyFilter.ShowAssignedFormsOnly');

                    $buttons
                        .attr('title', newHoverTitle)
                        .toggleClass('is-active', this.showLocationAssignedFormsOnly);

                    this.fillWindow();

                    Session.Settings[Enums.UserSettings.ShowOnlyAssignedForms] = this.showLocationAssignedFormsOnly;

                    Settings.MarkChanged(Enums.UserSettings.ShowOnlyAssignedForms);
                    Settings.Save();
                }
            }
        }

        showFormSelection(options);
    }

    function onLocationSchedulingItemClick(id?: string): void {
        const menuItem = Menu.GetMenuItemConfig(id);
        const options: Model.SelectionWindow.Options = <Model.SelectionWindow.Options>{
            Location: Session.CurrentLocation,
            Filter: Model.SelectionWindow.Filter.FromMenuItemConfiguration(menuItem),
            OnAfterItemsSelected: (selectedIdentifiers: string[]): void => {
                if (!selectedIdentifiers.length) {
                    return;
                }

                const schedulingIdentifier = selectedIdentifiers[0];
                const scheduling = DAL.Scheduling.GetByOID(schedulingIdentifier);

                if (!scheduling) {
                    return;
                }

                const defaultStateIdentifier = scheduling.StateOID || Session.Client.Settings.TicketOpened;

                if (!Utils.IsUserAbleToSetInitialState(Session.CurrentLocation.OID, defaultStateIdentifier)) {
                    Utils.Message.Show(i18next.t('Misc.RightError.MessageHeader'),
                        i18next.t('Misc.RightError.DefaultState.MessageBody'),
                        {
                            Close: {}
                        });

                    return;
                }

                Utils.IssueViewer.ExecuteScheduling(schedulingIdentifier, (schedulingIssue: Model.Issues.Issue) => {
                    Utils.Spinner.Show();
                    Utils.Router.PushState(`issue/${schedulingIssue.ID || schedulingIssue.OID}?type=${schedulingIssue.Type}`);
                });
            },
            OnOnlySingleApplicableItemExists: function(scheduling: Model.Scheduling.Scheduling) {
                if (!scheduling) {
                    return;
                }

                Utils.Message.Show(i18next.t('SelectionWindow.Schedulings.SingleApplicableItem.MessageHeader'),
                    i18next.t('SelectionWindow.Schedulings.SingleApplicableItem.MessageBody', {
                        ApplicableItemTitle: scheduling.Title
                    }),
                    {
                        Yes: () => {
                            this.selectedItemIdentifiers = [scheduling.OID];
                            this.options.OnAfterItemsSelected(this.selectedItemIdentifiers);
                        },
                        No: true
                    }, null, this.options.ZIndex + 50);
            }
        };

        showSchedulingSelection(options);
    }

    function onAfterIssueCreated(): void {
        Utils.Router.RefreshState();
    }

    function onBackButtonClick(): void {
        Utils.Spinner.Show();

        Utils.SetMode(Enums.Mode.Menu);

        currentIssue = IssueView.GetCurrentIssue();

        if (View.CurrentView === Enums.View.Main) {
            Utils.Router.PushState(getBaseAddress() + 'menu');
        } else if (View.CurrentView === Enums.View.IssueFullscreen) {
            if (!!IssueReport.CustomMenuID) {
                Utils.Router.PushState(getBaseAddress() + 'issue-report/' + IssueReport.CustomMenuID);
            } else {
                Utils.Router.PushState(getBaseAddress() + 'menu');
            }
        } else if (Utils.InArray([Enums.View.Scheduling, Enums.View.Inspection], View.CurrentView)) {
            Utils.Router.PushState(getBaseAddress() + 'menu?type=' + currentIssue.Type);
        }
    }

    function showFormSelection(options: Model.SelectionWindow.FormOptions): void {
        if (!Utils.CanUserCreateIssueType(Enums.IssueType.Form, Session.CurrentLocation) &&
            !Utils.CanUserCreateIssueType(Enums.IssueType.Inspection, Session.CurrentLocation)) {
            Utils.Message.Show(i18next.t('Misc.RightError.MessageHeader'),
                i18next.t('Misc.RightError.IssueCreation.MessageBody'),
                {
                    Close: true
                });

            return;
        }

        const formSelectionWindow = new Utils.FormSelectionWindow(options);
        formSelectionWindow.Show();
    }

    function showSchedulingSelection(options: Model.SelectionWindow.Options): void {
        if (!Utils.CanUserCreateIssueType(Enums.IssueType.Scheduling, Session.CurrentLocation)) {
            Utils.Message.Show(i18next.t('Misc.RightError.MessageHeader'),
                i18next.t('Misc.RightError.IssueCreation.MessageBody'),
                {
                    Close: true
                });

            return;
        }

        const schedulingSelectionWindow = new Utils.SchedulingSelectionWindow(options);
        schedulingSelectionWindow.Show();
    }

    function getStandardFollowStateInfo(): Array<Model.Properties.Property> {
        if (currentIssue) {
            const stateInfo = DAL.Properties.GetStateModificationInfo(currentIssue);
            const currentState = DAL.Properties.GetByOID(currentIssue.StateOID);

            if (stateInfo &&
                stateInfo.StateChangeAllowed &&
                stateInfo.FollowerStates.length &&
                !(currentState && currentState.ClosedState)) {
                return stateInfo.FollowerStates;
            }
        }

        return null;
    }

    function getBaseAddress(): string {
        if (currentIssue && Utils.InArray([Enums.View.Scheduling, Enums.View.Inspection], View.CurrentView)) {
            return `#issue/${currentIssue.ID || currentIssue.OID}/location/${Session.CurrentLocation.OID}/`;
        }

        return `#location/${Session.CurrentLocation.OID}/`;
    }

    function bindEvents(): void {
        $content.find('.scheduling').on('click', onLocationSchedulingItemClick);
        $content.find('.form-templates').on('click', onLocationFormItemClick);
        $content.find('li').on('click', onMenuItemClicked);
        $content.find('li .add').on('click', onMenuItemAddIconClick);

        if ($preview) {
            $preview.find('.menu-title').off().on('click', onPreviewTitleClick);
        }

        $('#content').off().on('resize', () => { FitResizeMenu() });
        $content.off().on('click', '.switch.previous, .switch.next', (evt: Event) => {
            if ($(evt.currentTarget).hasClass('previous')) {
                showPreviousPreviewItem();
            } else {
                showNextPreviewItem();
            }
        });
    }

    function showPreviousPreviewItem(): void {
        currentPreviewItemIdx--;
        if (currentPreviewItemIdx < 0) {
            currentPreviewItemIdx = menuItemsWithPreview.length - 1;
        }

        showPreviewForItem(currentPreviewItemIdx)
    }

    function showNextPreviewItem(): void {
        currentPreviewItemIdx++;
        if (currentPreviewItemIdx >= menuItemsWithPreview.length) {
            currentPreviewItemIdx = 0;
        }

        showPreviewForItem(currentPreviewItemIdx)
    }

    function onMenuItemClicked(evt: Event) {
        evt.stopPropagation();
        evt.preventDefault();

        const itemID = $(evt.currentTarget).data('id');
        for (let i = 0; i < visibleMenuItems.length; i++) {
            const mItem = visibleMenuItems[i];
            if (mItem.ID == itemID) {
                if (mItem && mItem.OnSelect) {
                    mItem.OnSelect.call(mItem);
                }
                break;
            }
        }
    }

    function onMenuItemAddIconClick(evt: Event) {
        evt.stopPropagation();
        evt.preventDefault();

        const itemID = $(evt.currentTarget).closest('li').data('id');
        for (let i = 0; i < visibleMenuItems.length; i++) {
            const mItem = visibleMenuItems[i];
            if (mItem.ID == itemID) {
                if (mItem && mItem.OnAdd) {
                    mItem.OnAdd.call(mItem);
                }
                break;
            }
        }
    }

    function onPreviewTitleClick() {
        if (!menuItemsWithPreview || !menuItemsWithPreview.length) {
            return;
        }

        const mItem = menuItemsWithPreview[currentPreviewItemIdx];

        if (mItem && mItem.OnSelect) {
            mItem.OnSelect.call(mItem);
        }
    }

    function getFormTemplateCounter(menuID?: string): { Total: number, AvailableFormsAtCurrentLocation: number } {
        const filter = Model.SelectionWindow.Filter.FromMenuItemConfiguration(Menu.GetMenuItemConfig(menuID));
        const allowRegularForms = Utils.CanUserCreateIssueType(Enums.IssueType.Form, Session.CurrentLocation);
        const allowInspections =
            Utils.CanUserCreateIssueType(Enums.IssueType.Inspection, Session.CurrentLocation) &&
            Session.Client.Licenses.Inspections;
        const formRoot = DAL.Elements.FormRoot;

        let totalCount = 0;
        let availableFormsAtCurrentLocation = 0;

        if (!formRoot) {
            return { Total: 0, AvailableFormsAtCurrentLocation: 0 };
        }

        (function traverse(form: Model.Elements.Element) {
            let hasParameters = form.HasCheckpoints || false;

            // alternativ Prüfung ob Prüfpunkte vorhanden sind
            if (!hasParameters && (form.Parametergroups || []).length > 0) {
                for (const paramGroup of form.Parametergroups) {
                    if ((paramGroup.Parameters || []).length) {
                        hasParameters = true;
                        break;
                    }
                }
            }

            const hasActiveFilters = filter.AreFiltersActive();

            if (!form.IsSurvey &&
                !form.IsInvestigation && hasParameters) {
                if (form.IsInspection && allowInspections ||
                    !form.IsInspection && allowRegularForms) {
                    if (!hasActiveFilters || Utils.HasIntersection(form.Properties, filter.Keywords)) {
                        totalCount++;

                        if (Utils.InArray(Session.CurrentLocation.Forms, form.OID)) {
                            availableFormsAtCurrentLocation++;
                        }
                    }
                }
            }

            (form.Children || []).forEach(traverse);
        })(formRoot);

        return { Total: totalCount, AvailableFormsAtCurrentLocation: availableFormsAtCurrentLocation };
    }

    function getCountOfSchedulings(menuID?: string): number {
        const filter = Model.SelectionWindow.Filter.FromMenuItemConfiguration(Menu.GetMenuItemConfig(menuID));
        const schedulings = DAL.Scheduling.GetAvailableSchedulingsAtLocation(Session.CurrentLocation.OID);

        if (!Utils.IsSet(schedulings)) {
            return 0;
        }

        const sLen = schedulings.length;

        if (!filter.AreFiltersActive()) {
            return sLen;
        }

        let count = 0;
        for (let sCnt = 0; sCnt < sLen; sCnt++) {
            const scheduling = DAL.Scheduling.GetByOID(schedulings[sCnt]);

            if (!Utils.IsSet(scheduling)) {
                continue;
            }

            if (Utils.HasIntersection(scheduling.Classifications, filter.Classifications)) {
                count++;
            }
        }

        return count;
    }

    function getCountOfParameters(menuID?: string): number {
        const menu = Menu.GetMenuItemConfig(menuID);
        const filters = Utils.IsSet(menu) && Utils.IsSet(menu.Configuration) ?
            Model.Parameters.Filter.FromMenuItemConfiguration(menu) :
            ParameterList.GetFilters();

        if (!(Session.CurrentLocation.Parametergroups || []).length) {
            return 0;
        }

        let searchRegExp;

        if (!!filters.SearchText) {
            searchRegExp = new RegExp(Utils.EscapeRegExPattern(filters.SearchText), 'i');
        }

        const visibleParameterGroups = [
            Enums.ElementType.FormFooter,
            Enums.ElementType.FormHeader,
            Enums.ElementType.FormRow,
            Enums.ElementType.Parametergroup,
            Enums.ElementType.SingletonFormRow
        ];

        let counter = 0;

        for (let gCnt = 0, gLen = Session.CurrentLocation.Parametergroups.length; gCnt < gLen; gCnt++) {
            const group = Session.CurrentLocation.Parametergroups[gCnt];

            if (group.Attribute === 5) {
                continue;
            }

            if (!(group.Parameters || []).length) {
                continue;
            }

            if (!Utils.InArray(visibleParameterGroups, group.Type)) {
                continue;
            }

            let groupHasKeywordFromFilter = false;

            if ((filters.Keywords || []).length) {
                groupHasKeywordFromFilter = Utils.HasIntersection(filters.Keywords, group.Properties);
            }

            if (filters.SearchInGroup) {
                if (searchRegExp && !searchRegExp.test(group.Title) && !searchRegExp.test(group.Description)) {
                    continue;
                }

                counter += group.Parameters.length;

                continue;
            }

            for (let pCnt = 0, pLen = group.Parameters.length; pCnt < pLen; pCnt++) {
                const param = group.Parameters[pCnt];

                if (!param.Enabled) {
                    continue;
                }

                if (View.CurrentView === Enums.View.Main &&
                    param.Type === Enums.ElementType.EMailAddresses &&
                    !Utils.IsEMailCpEnabled()) {
                    continue;
                }

                if ((filters.Keywords || []).length &&
                    !groupHasKeywordFromFilter &&
                    !Utils.HasIntersection(filters.Keywords, param.Properties)) {
                    continue;
                }

                const recorditem = param.LastRecorditem || <Model.Recorditem>{ Type: Enums.RecorditemType.UNRECORDED, IsDummy: true };

                if (recorditem.IsDummy || recorditem.Type === Enums.RecorditemType.UNRECORDED) {
                    if (!filters.ShowRequiredUnrecordedParameters && param.Required) {
                        continue;
                    }

                    if (!filters.ShowUnrecordedParameters && !param.Required) {
                        continue;
                    }
                } else if (!filters.ShowRecordedParameters) {
                    continue;
                }

                if ((filters.ValueCategories || []).length && !Utils.InArray(filters.ValueCategories, recorditem.CategoryOID)) {
                    continue;
                }

                if (searchRegExp && !searchRegExp.test(param.Title) && !searchRegExp.test(param.Description)) {
                    continue;
                }

                counter++;
            }
        }

        return counter;
    }

    function getWhetherFormTemplateButtonIsAvailable(availableMenuItems: Array<string>): boolean {
        if (!Utils.InArray(availableMenuItems, Enums.MenuItemID.FormTemplates)) {
            return false;
        }

        const location = Session.CurrentLocation;

        if (!Utils.CanUserCreateIssueType(Enums.IssueType.Form, location) &&
            !Utils.CanUserCreateIssueType(Enums.IssueType.Inspection, location)) {
            return false;
        }

        return Utils.UserHasRight(Session.User.OID, Enums.Rights.SeeAllForms, true, location) ||
            (location.Forms || []).length > 0;
    }

    function getWhetherSchedulingTemplateButtonIsAvailable(menuItemId: string, availableMenuItems: Array<string>, schedulingCount: number): boolean {
        if (!Utils.InArray(availableMenuItems, menuItemId)) {
            return false;
        }

        const location = Session.CurrentLocation;

        return Utils.CanUserCreateIssueType(Enums.IssueType.Scheduling, location) &&
            schedulingCount > 0;
    }

    function getWhetherFloorPlanButtonIsAvailable(availableMenuItems: Array<string>): boolean {
        let location = Session.CurrentLocation;
        let showFloorPlanButton = Utils.InArray(availableMenuItems, Enums.MenuItemID.FloorPlan);

        if (!showFloorPlanButton) {
            return;
        }

        showFloorPlanButton = !!location.Layout;

        while (!showFloorPlanButton && (location = location.Parent)) {
            showFloorPlanButton = !!location.Layout;
        }

        return showFloorPlanButton;
    }

    function getInspectionTemplateContext(counters: any, availableMenuItems: Array<any>): Array<Model.Menu.MenuItem> {
        // !! ATTENTION !!
        // !! NEEDS REFACTORING IF RE-ENABLED !!
        // !! ATTENTION !!
        const visibleMenuItems: Array<Model.Menu.MenuItem> = [];

        // Covering Page
        {
            const mItem = new Model.Menu.MenuItem("CoveringPage");
            mItem.Title = i18next.t('Inspection.CoveringPage');
            mItem.ActionAttribute = Enums.Menu.Action.Parameters;
            mItem.Icon = Enums.Menu.Icon.Parameters;
            mItem.CounterText = counters.CoveringPageParameters;
            mItem.OnSelect = function() {
                Utils.Router.PushState(getBaseAddress() + 'covering-page?type=6');
            };
            visibleMenuItems.push(mItem);
        }

        // Issues
        {
            // clone from basic item
            const mItem = menuItems[Enums.Menu.SystemID.IssuesReport].clone();
            mItem.CounterText = counters.SubIssues;
            mItem.ShowAddButton = false;
            mItem.OnSelect = function() {
                Utils.Router.PushState(getBaseAddress() + 'issue-report?type=6');
            };
            visibleMenuItems.push(mItem);
        }

        // Parameters
        if (Utils.InArray(availableMenuItems, Enums.MenuItemID.Parameters)) {
            // clone from basic item
            const mItem = menuItems[Enums.Menu.SystemID.Parameters].clone();
            mItem.CounterText = counters.Parameters;
            mItem.ShowAddButton = false;
            mItem.OnSelect = function() {
                Utils.Router.PushState(getBaseAddress() + `parameters?type=${currentIssue.Type}`);
            };
            visibleMenuItems.push(mItem);
        }

        // Information
        if (Utils.InArray(availableMenuItems, Enums.MenuItemID.Information)) {
            // clone from basic item
            const mItem = menuItems[Enums.Menu.SystemID.Information].clone();
            mItem.OnSelect = function() {
                Utils.Router.PushState(getBaseAddress() + `information?type=${currentIssue.Type}`);
            };
            visibleMenuItems.push(mItem);
        }

        // FloorPlan
        if (getWhetherFloorPlanButtonIsAvailable(availableMenuItems)) {
            // TODO  clone from basic item
            const mItem = menuItems[Enums.Menu.SystemID.FloorPlan].clone();
            mItem.OnSelect = function() {
                Utils.Router.PushState(getBaseAddress() + `floor-plan`);
            };
            visibleMenuItems.push(mItem);
        }

        // Close
        {
            const mItem = new Model.Menu.MenuItem(Enums.Menu.SystemID.Close);
            mItem.Title = i18next.t('IssueView.Close');
            mItem.ActionAttribute = Enums.Menu.Action.Close;
            mItem.Icon = Enums.Menu.Icon.Close;
            mItem.OnSelect = function() {
                Utils.Router.PushState(`#location/${DAL.Elements.Root.OID}/menu`);
            };
            visibleMenuItems.push(mItem);
        }

        return visibleMenuItems;
    }

    function getIssueListMenuItems(counters: any, availableMenuItems: Array<any>): Array<Model.Menu.MenuItem> {
        // TODO set visibility by role
        const visibleMenuItems: Array<Model.Menu.MenuItem> = [];

        // Assigned Scheduling
        {
            const mItem = new Model.Menu.MenuItem("AssignedScheduling");
            const assignedScheduling = DAL.Scheduling.GetByOID(currentIssue.AssignedSchedulingOID);
            mItem.Title = assignedScheduling ? assignedScheduling.Title : i18next.t('Misc.Unknown');
            mItem.ActionAttribute = Enums.Menu.Action.Parameters;
            mItem.Icon = Enums.Menu.Icon.Scheduling;
            mItem.OnSelect = function() {
                Utils.Router.PushState(getBaseAddress() + `parameters?type=${currentIssue.Type}`);
            };
            visibleMenuItems.push(mItem);
        }

        // Parameters
        {
            // clone from basic item
            const mItem = menuItems[Enums.Menu.SystemID.Parameters].clone();
            mItem.CounterText = counters.Parameters;
            mItem.OnSelect = function() {
                Utils.Router.PushState(getBaseAddress() + `parameters?type=${currentIssue.Type}`);
            };
            visibleMenuItems.push(mItem);
        }

        // Information
        if (Utils.InArray(availableMenuItems, Enums.MenuItemID.Information)) {
            // clone from basic item
            const mItem = menuItems[Enums.Menu.SystemID.Information].clone();
            mItem.OnSelect = function() {
                Utils.Router.PushState(getBaseAddress() + `information?type=${currentIssue.Type}`);
            };
            visibleMenuItems.push(mItem);
        }

        // FloorPlan
        if (getWhetherFloorPlanButtonIsAvailable(availableMenuItems)) {
            // clone from basic item
            const mItem = menuItems[Enums.Menu.SystemID.FloorPlan].clone();
            mItem.OnSelect = function() {
                Utils.Router.PushState(getBaseAddress() + `floor-plan?type=${currentIssue.Type}`);
            };
            visibleMenuItems.push(mItem);
        }

        // Close
        {
            const mItem = new Model.Menu.MenuItem(Enums.Menu.SystemID.Close);
            mItem.Title = i18next.t('IssueView.Close');
            mItem.ActionAttribute = Enums.Menu.Action.Close;
            mItem.Icon = Enums.Menu.Icon.Close;
            mItem.OnSelect = function() {
                Utils.Router.PushState(`#location/${DAL.Elements.Root.OID}/menu`);
            };
            visibleMenuItems.push(mItem);
        }

        return visibleMenuItems;
    }

    function getMainTemplateContext(counters: any): Array<any> {
        let availableMenuItems = Utils.GetAvailableContentMenuItemsByRoles(Enums.MenuSection.Content, Session.CurrentLocation.OID);
        const templateContext = {
            AssignedScheduling: null,
            AvailableFormTemplateCount: null,
            AvailableMenuItems: null,
            AvailableSchedulingCount: null,
            BaseAddress: getBaseAddress(),
            CoveringPageParameterCount: null,
            DisturbanceInfo: null,
            FormTemplateCount: null,
            HideIssueReport: null,
            Issue: null,
            IssueFiltersActive: IssueReport.AreFiltersActive(),
            IssueInfo: null,
            LocationOID: Session.CurrentLocation.OID,
            NoteInfo: null,
            ParameterCount: null,
            RootLocationOID: DAL.Elements.Root.OID,
            ShowFloorPlanButton: getWhetherFloorPlanButtonIsAvailable(availableMenuItems),
            ShowFormTemplateButton: getWhetherFormTemplateButtonIsAvailable(availableMenuItems),
            ShowFormTemplateCount: true,
            ShowInspectionListItems: null,
            ShowIssueListItems: null,
            ShowIssueReportOnly: null,
            ShowSchedulingTemplateButton: null,
            StandardFollowState: null,
            SubIssueCount: null,
            TaskInfo: null
        };

        if (!Utils.CanUserSeeLocation(Session.User.OID, Session.CurrentLocation)) {
            availableMenuItems = [];

            if ((counters.Tasks && counters.Tasks.IssueCount > 0) ||
                (counters.IssueInformation && counters.IssueInformation.IssueCount)) {
                availableMenuItems.push(Enums.MenuItemID.TaskReport);
            }

            if (counters.Notes && counters.Notes.IssueCount > 0) {
                availableMenuItems.push(Enums.MenuItemID.NoteReport);
            }

            if (counters.Disturbances && counters.Disturbances.IssueCount > 0) {
                availableMenuItems.push(Enums.MenuItemID.DisturbanceReport);
            }
        }

        if (!Utils.UserHasRight(Session.User.OID, Enums.Rights.SeeAllForms, true, Session.CurrentLocation) ||
            Session.Settings.ShowOnlyAssignedForms) {
            templateContext.ShowFormTemplateCount = false;
        }

        if (!templateContext.ShowFloorPlanButton &&
            Utils.InArray(availableMenuItems, Enums.MenuItemID.FloorPlan)) {
            availableMenuItems.splice(availableMenuItems.indexOf(Enums.MenuItemID.FloorPlan), 1);
        }

        if (!!counters) {
            if (!Session.Settings.ShowAllIssuesInOneReport) {
                templateContext.TaskInfo = counters.Tasks;
                templateContext.NoteInfo = counters.Notes;
                templateContext.DisturbanceInfo = counters.Disturbances;
            } else {
                templateContext.IssueInfo = counters.IssueInformation;
            }

            templateContext.ParameterCount = counters.Parameters = getCountOfParameters();

            if (Utils.InArray(availableMenuItems, Enums.MenuItemID.Scheduling)) {
                templateContext.AvailableSchedulingCount = counters.Schedulings;
                templateContext.ShowSchedulingTemplateButton =
                    getWhetherSchedulingTemplateButtonIsAvailable(
                        Enums.MenuItemID.Scheduling,
                        availableMenuItems,
                        templateContext.AvailableSchedulingCount);
            }

            if (Utils.InArray(availableMenuItems, Enums.MenuItemID.FormTemplates)) {
                templateContext.AvailableFormTemplateCount = counters.FormTemplates;

                if (templateContext.ShowFormTemplateButton &&
                    templateContext.ShowFormTemplateCount) {
                    templateContext.FormTemplateCount = getFormTemplateCounter().Total;
                }

                if (templateContext.ShowFormTemplateButton &&
                    !Utils.UserHasRight(Session.User.OID, Enums.Rights.SeeAllForms, true, Session.CurrentLocation)) {
                    templateContext.ShowFormTemplateButton = templateContext.AvailableFormTemplateCount > 0;
                }
            }

            templateContext.SubIssueCount = counters.SubIssues;
            templateContext.CoveringPageParameterCount = counters.CoveringPageParameters;
        }

        if (Session.Settings.ShowAllIssuesInOneReport) {
            templateContext.ShowIssueReportOnly = true;

            if (!(Utils.InArray(availableMenuItems, Enums.MenuItemID.TaskReport) ||
                Utils.InArray(availableMenuItems, Enums.MenuItemID.NoteReport) ||
                Utils.InArray(availableMenuItems, Enums.MenuItemID.DisturbanceReport))) {
                templateContext.HideIssueReport = true;
            }
        }

        templateContext.AvailableMenuItems = (availableMenuItems || []).join(',');

        if (Utils.InArray([Enums.View.Form, Enums.View.Inspection, Enums.View.Scheduling], View.CurrentView) &&
            currentIssue) {
            if (currentIssue.Type !== Enums.IssueType.Inspection) {
                templateContext.ShowIssueListItems = true;
            } else {
                templateContext.ShowInspectionListItems = true;
            }

            templateContext.Issue = currentIssue;
            templateContext.StandardFollowState = getStandardFollowStateInfo();
        }

        if (View.CurrentView === Enums.View.Scheduling) {
            templateContext.AssignedScheduling = DAL.Scheduling.GetByOID(currentIssue.AssignedSchedulingOID);
        }

        if (Utils.InArray([Enums.View.Form, Enums.View.Inspection, Enums.View.Scheduling], View.CurrentView) && currentIssue) {
            if (currentIssue.Type == Enums.IssueType.Inspection) {
                // 'ShowInspectionListItems'
                visibleMenuItems = getInspectionTemplateContext(counters, availableMenuItems);
            } else {
                // 'ShowIssueListItems'
                visibleMenuItems = getIssueListMenuItems(counters, availableMenuItems);
            }
        } else {
            visibleMenuItems = getMainMenuItems(counters, availableMenuItems);
        }

        return visibleMenuItems.length ? visibleMenuItems : null;
    }

    function renderMenu(counters: Model.Menu.Counters): void {
        $content = Utils.GetContentContainer().empty();
        const mainTemplateContext = getMainTemplateContext(counters);
        const visibleMenuItems = mainTemplateContext;
        menuItemsWithPreview = (visibleMenuItems || []).filter((mItem: Model.Menu.MenuItem) => mItem.EnablePreview);

        const $menuContent = $(Templates.Menus.Content({
            MenuItems: mainTemplateContext,
            EnablePreviewWindow: menuItemsWithPreview.length > 0,
            ShowPreviewSwitch: menuItemsWithPreview.length > 1,
        }));
        $content.append($menuContent);

        if (Session.Settings.ShowMenuItemsAsTiles && mainTemplateContext != null) {
            const $menuItemsContainer = $menuContent.is('ul.content-selection') ?
                $menuContent :
                $menuContent.find('ul.content-selection');

            $menuItemsContainer.addClass('tiled');
        }

        if (menuItemsWithPreview && menuItemsWithPreview.length) {
            // Vorgangsvorschau initialisieren
            $preview = $menuContent.find('.preview');

            if (lastPreviewItemID) {
                // versuchen vorherige Ansicht wiederherzustellen
                currentPreviewItemIdx = menuItemsWithPreview.findIndex((item: Model.Menu.MenuItem) => item.ID == lastPreviewItemID);
                currentPreviewItemIdx = Math.max(0, currentPreviewItemIdx);
                lastPreviewItemID = null;
            }

            showPreviewForItem(currentPreviewItemIdx);
        } else {
            $preview = null;
        }

        FitResizeMenu();
    }

    function showPreviewForItem(idx: number): void {
        if (!menuItemsWithPreview || !menuItemsWithPreview.length || !$preview) {
            return;
        }

        if (idx >= menuItemsWithPreview.length) {
            idx = idx % menuItemsWithPreview.length;
        }

        const currentPreviewItem = menuItemsWithPreview[idx];

        switch (currentPreviewItem.ActionAttribute) {
            case Enums.Menu.Action.Issues:
                IssueReport.ShowByMenu(currentPreviewItem.ID, $preview.find('.preview-container'));
                break;
            case Enums.Menu.Action.Info:
                Information.Show($preview.find('.preview-container'), currentPreviewItem.ID);
                break;
        }

        $preview.find('.menu-title').text(currentPreviewItem.Title);

        if (currentPreviewItem.ItemMarker) {
            $preview.addClass(currentPreviewItem.ItemMarker);
        }
    }

    function getIEVersion(): number {
        let sAgent = window.navigator.userAgent;
        let idx = sAgent.indexOf("MSIE");

        if (idx > 0) {
            return parseInt(sAgent.substring(idx + 5, sAgent.indexOf(".", idx)));
        } else if (!!navigator.userAgent.match(/Trident\/7\./)) {
            return 11;
        } else {
            return 0; //It is not IE
        }
    }

    export function OnIssueModified(issue: Model.Issues.Issue) {
        if (!menuItemsWithPreview || !menuItemsWithPreview.length) {
            return;
        }

        IssueReport.OnIssueModified(issue);
    }

    export function Show(counters: Model.Menu.Counters): void {
        Utils.SetMode(Enums.Mode.Menu);

        if (menuItemsWithPreview && menuItemsWithPreview.length > currentPreviewItemIdx) {
            const currentPreviewItem = menuItemsWithPreview[currentPreviewItemIdx];
            lastPreviewItemID = currentPreviewItem.ID;
        }

        menuItemsWithPreview = null;
        currentPreviewItemIdx = 0;

        currentIssue = IssueView.GetCurrentIssue();

        renderMenu(counters);

        if (getIEVersion() > 0) {
            $('#content > div > ul > li.parameters > a > div.col-xs-1.icon').css('background-size', '20px 20px');
        }

        bindEvents();

        $('.navbar-content .btn-issue-filters').removeClass('invisible');

        Utils.Spinner.Unlock();
        Utils.Spinner.HideWithTimeout();

        if (View.CurrentView === Enums.View.Scheduling) {
            ParameterList.SetFilterState();
        } else {
            IssueReport.SetFilterState();
            App.UpdateTreeRoute();
        }

        // Kein RefreshView mitten in Begehungen oder Plan ausführen
        if (View.CurrentView !== Enums.View.Scheduling &&
            View.CurrentView !== Enums.View.Inspection) {
            View.SetRefreshMethod(RefreshView);
        }
    }

    export function HideBackButton(): void {
        $backButton.addClass('hidden');
    }

    export function ShowBackButton(): void {
        currentIssue = IssueView.GetCurrentIssue();

        $backButton.removeClass('hidden');
    }

    export function RefreshView(): void {
        if (View.CurrentView !== Enums.View.IndividualData) {
            // prevent loading menu counters within Individualdata view
            App.GetBadgeCounters()
                .then((counters) => Menu.Show(counters));
        }
    }

    function onAddIssue(): void {
        let issueType: Enums.IssueType = Enums.IssueType.Task;
        let defaultStateOID = Session.Client.Settings.TicketOpened;

        switch ((<Model.Menu.MenuItem>this).ID) {
            case 'NoteReport':
                issueType = Enums.IssueType.Note;
                break;
            case 'DisturbanceReport':
                issueType = Enums.IssueType.Disturbance;
                defaultStateOID = Session.Client.Settings.DisturbanceOpened;
                break;
        }

        if (!Utils.IsUserAbleToSetInitialState(Session.CurrentLocation.OID, defaultStateOID)) {
            Utils.Message.Show(i18next.t('Misc.RightError.MessageHeader'),
                i18next.t('Misc.RightError.DefaultState.MessageBody'),
                {
                    Close: true
                });

            return;
        }

        Utils.IssueViewer.CreateIssue(issueType, onAfterIssueCreated);
    }

    function getMenuItem(id: Enums.Menu.SystemID | string): Model.Menu.MenuItem {
        const mItem = menuItems[id] || new Model.Menu.MenuItem(id);
        menuItems[mItem.ID] = mItem;
        return mItem;
    }

    function getMenuItemTitle(itemConfig: Model.Menu.IMenuItemConfig, altTitle: string): string {
        if (itemConfig && itemConfig.Title) {
            return itemConfig.Title;
        }
        return altTitle;
    }

    function onCustomItemSelected() {
        const menuItemDefinition = Menu.GetMenuItemConfig(this.ID);

        switch (this.ActionAttribute) {
            case Enums.Menu.Action.Calendar:
                Utils.Router.PushState(getBaseAddress() + 'calendar');
                break;
            case Enums.Menu.Action.Issues:
                if (menuItemDefinition.Configuration && menuItemDefinition.Configuration.PoolFormIssues) {
                    if (!Session.IsSmartDeviceApplication) {
                        return;
                    }

                    Utils.Router.PushState(getBaseAddress() + `issue-pool/${this.ID}`);
                } else {
                    Utils.Router.PushState(getBaseAddress() + `issue-report/${this.ID}`);
                }
                break;
            case Enums.Menu.Action.Parameters:
                if (Utils.InArray([Enums.View.Form, Enums.View.Inspection, Enums.View.Scheduling], View.CurrentView) && currentIssue) {
                    Utils.Router.PushState(getBaseAddress() + `parameters?type=${currentIssue.Type}`);
                } else {
                    Utils.Router.PushState(getBaseAddress() + `parameters/${this.ID}`);
                }
                break;
            case Enums.Menu.Action.Scheduling:
                onLocationSchedulingItemClick(this.ID);
                break;
            case Enums.Menu.Action.FormTemplates:
                onLocationFormItemClick(this.ID);
                break;
            case Enums.Menu.Action.Info:
                Utils.Router.PushState(getBaseAddress() + `information/${this.ID}`);
                break;
            case Enums.Menu.Action.FloorPlan:
                Utils.Router.PushState(getBaseAddress() + `floor-plan`);
                break;
            case Enums.Menu.Action.BluetoothConfiguration:
                BluetoothConfiguration.Show();
                break;
            case Enums.Menu.Action.Link:
                if (!Utils.IsSet(menuItemDefinition.Configuration) || !menuItemDefinition.Configuration.WebAddress) {
                    return;
                }

                let url = menuItemDefinition.Configuration.WebAddress;

                if (!/^http(s)?:\/\//.test(url)) {
                    url = 'http://' + url;
                }

                if (navigator.app && navigator.app.loadUrl instanceof Function) {
                    navigator.app.loadUrl(url, { openExternal: true });
                } else if (window.cordova && window.cordova.InAppBrowser) {
                    window.cordova.InAppBrowser.open(url, '_system');
                } else {
                    window.open(url, '_system');
                }
                break;
            case Enums.Menu.Action.IndividualData:
                const type = menuItemDefinition.Configuration.IndividualDataSchema;
                Utils.Router.PushState(`individual-data/${menuItemDefinition.ID}`);
                break;
        }
    }

    export function GenerateMenuItems(config?: Array<Model.Menu.IMenuItemConfig>): void {
        menuItemsConfig = config || [];

        // parse config to object
        const parsedConfig: { [id: string]: Model.Menu.IMenuItemConfig } = {};
        if (config) {
            for (let i = 0; i < config.length; i++) {
                const tmp = config[i];

                // remove deleted
                if (tmp.Deleted) {
                    delete menuItems[tmp.ID];
                }

                if (tmp.ActionType == Enums.Menu.Action.BluetoothConfiguration
                    && !Session.IsSmartDeviceApplication) {
                    continue;
                }

                if (tmp.ActionType === Enums.Menu.Action.Issues &&
                    tmp.Configuration && tmp.Configuration.PoolFormIssues &&
                    !Session.IsSmartDeviceApplication) {
                    continue;
                }

                tmp.Title = Utils.EscapeHTMLEntities(tmp.Title);

                // save parsed config
                parsedConfig[tmp.ID] = tmp;

                const mItem = getMenuItem(tmp.ID);
                mItem.EnablePreview = tmp.EnablePreview;

                // add custom menuitems
                if (!tmp.IsSystemEntity) {
                    mItem.Title = tmp.Title;
                    mItem.Description = tmp.Description;
                    mItem.Icon = getDefaultIcon(tmp);
                    mItem.IsAvailableInRecordingApp = typeof tmp.IsAvailableInRecordingApp === 'boolean' ?
                        tmp.IsAvailableInRecordingApp :
                        true;
                    mItem.ActionAttribute = tmp.ActionType;
                    mItem.ShowAddButton = false;
                    mItem.OnSelect = onCustomItemSelected;
                }
            }
        }

        // Calendar
        {
            const mItem = getMenuItem(Enums.Menu.SystemID.Calendar);
            mItem.Title = getMenuItemTitle(parsedConfig[Enums.Menu.SystemID.Calendar], i18next.t('Calendar.Title'));
            mItem.ActionAttribute = Enums.Menu.Action.Calendar;
            mItem.Icon = Enums.Menu.Icon.Calendar;
            mItem.IsAvailableInRecordingApp = typeof mItem.IsAvailableInRecordingApp === 'boolean' ?
                mItem.IsAvailableInRecordingApp :
                true;
            mItem.OnSelect = function() {
                Utils.Router.PushState(getBaseAddress() + 'calendar');
            };
        }

        // Issues all-in-one
        {
            const mItem = getMenuItem(Enums.Menu.SystemID.IssuesReport);
            mItem.Title = getMenuItemTitle(parsedConfig[Enums.Menu.SystemID.IssuesReport], i18next.t('Misc.Issue_plural'));
            mItem.ActionAttribute = Enums.Menu.Action.Issues;
            mItem.Icon = Enums.Menu.Icon.Issues;
            mItem.IsAvailableInRecordingApp = typeof mItem.IsAvailableInRecordingApp === 'boolean' ?
                mItem.IsAvailableInRecordingApp :
                true;
            mItem.ItemMarker = Enums.IssueCellMarker.Red;
            mItem.CounterText = '0';
            mItem.OnSelect = function() {
                Utils.Router.PushState(getBaseAddress() + 'issue-report');
            };
        }

        // Tasks
        {
            const mItem = getMenuItem(Enums.Menu.SystemID.TaskReport);
            mItem.Title = getMenuItemTitle(parsedConfig[Enums.Menu.SystemID.TaskReport], i18next.t('Misc.IssueType.Task_plural'));
            mItem.ActionAttribute = Enums.Menu.Action.Issues;
            mItem.Icon = Enums.Menu.Icon.Tasks;
            mItem.IsAvailableInRecordingApp = typeof mItem.IsAvailableInRecordingApp === 'boolean' ?
                mItem.IsAvailableInRecordingApp :
                true;
            mItem.ShowAddButton = true;
            mItem.ItemMarker = Enums.IssueCellMarker.Green;
            mItem.CounterText = '0';
            mItem.OnSelect = function() {
                Utils.Router.PushState(getBaseAddress() + 'task-report');
            };
            mItem.OnAdd = onAddIssue;
        }

        // Notes
        {
            const mItem = getMenuItem(Enums.Menu.SystemID.NoteReport);
            mItem.Title = getMenuItemTitle(parsedConfig[Enums.Menu.SystemID.NoteReport], i18next.t('Misc.IssueType.Note_plural'));
            mItem.ActionAttribute = Enums.Menu.Action.Issues;
            mItem.Icon = Enums.Menu.Icon.Notes;
            mItem.IsAvailableInRecordingApp = typeof mItem.IsAvailableInRecordingApp === 'boolean' ?
                mItem.IsAvailableInRecordingApp :
                true;
            mItem.ShowAddButton = true;
            mItem.ItemMarker = Enums.IssueCellMarker.Yellow;
            mItem.CounterText = '0';
            mItem.OnSelect = function() {
                Utils.Router.PushState(getBaseAddress() + 'note-report');
            };
            mItem.OnAdd = onAddIssue;
        }

        // Disturbances
        {
            const mItem = getMenuItem(Enums.Menu.SystemID.DisturbanceReport);
            mItem.Title = getMenuItemTitle(parsedConfig[Enums.Menu.SystemID.DisturbanceReport], i18next.t('Misc.IssueType.Disturbance_plural'));
            mItem.ActionAttribute = Enums.Menu.Action.Issues;
            mItem.Icon = Enums.Menu.Icon.Disturbances;
            mItem.IsAvailableInRecordingApp = typeof mItem.IsAvailableInRecordingApp === 'boolean' ?
                mItem.IsAvailableInRecordingApp :
                true;
            mItem.ShowAddButton = true;
            mItem.ItemMarker = Enums.IssueCellMarker.Red;
            mItem.CounterText = '0';
            mItem.OnSelect = function() {
                Utils.Router.PushState(getBaseAddress() + 'disturbance-report');
            };
            mItem.OnAdd = onAddIssue;
        }

        // Scheduling
        {
            const mItem = getMenuItem(Enums.Menu.SystemID.Scheduling);
            mItem.Title = getMenuItemTitle(parsedConfig[Enums.Menu.SystemID.Scheduling], i18next.t('Misc.Scheduling_plural'));
            mItem.ActionAttribute = Enums.Menu.Action.Scheduling;
            mItem.Icon = Enums.Menu.Icon.Scheduling;
            mItem.IsAvailableInRecordingApp = typeof mItem.IsAvailableInRecordingApp === 'boolean' ?
                mItem.IsAvailableInRecordingApp :
                true;
            mItem.CounterText = '0';
            mItem.OnSelect = onLocationSchedulingItemClick;
        }

        // Parameters
        {
            const mItem = getMenuItem(Enums.Menu.SystemID.Parameters);
            mItem.Title = getMenuItemTitle(parsedConfig[Enums.Menu.SystemID.Parameters], i18next.t('Misc.Parameter_plural'));
            mItem.ActionAttribute = Enums.Menu.Action.Parameters;
            mItem.Icon = Enums.Menu.Icon.Parameters;
            mItem.IsAvailableInRecordingApp = typeof mItem.IsAvailableInRecordingApp === 'boolean' ?
                mItem.IsAvailableInRecordingApp :
                true;
            mItem.CounterText = '0';
            mItem.OnSelect = function() {
                if (Utils.InArray([Enums.View.Form, Enums.View.Inspection, Enums.View.Scheduling], View.CurrentView) && currentIssue) {
                    Utils.Router.PushState(getBaseAddress() + `parameters?type=${currentIssue.Type}`);
                } else {
                    Utils.Router.PushState(getBaseAddress() + 'parameters');
                }
            };
        }

        // FormTemplates
        {
            const mItem = getMenuItem(Enums.Menu.SystemID.FormTemplates);
            mItem.Title = getMenuItemTitle(parsedConfig[Enums.Menu.SystemID.FormTemplates], i18next.t('Misc.Form_plural'));
            mItem.ActionAttribute = Enums.Menu.Action.FormTemplates;
            mItem.Icon = Enums.Menu.Icon.Forms;
            mItem.IsAvailableInRecordingApp = typeof mItem.IsAvailableInRecordingApp === 'boolean' ?
                mItem.IsAvailableInRecordingApp :
                true;
            mItem.CounterText = '0/0';
            mItem.BadgeCounterAutoWidth = true;
            mItem.OnSelect = onLocationFormItemClick;
        }

        // FloorPlan
        {
            const mItem = getMenuItem(Enums.Menu.SystemID.FloorPlan);
            mItem.Title = getMenuItemTitle(parsedConfig[Enums.Menu.SystemID.FloorPlan], i18next.t('Misc.FloorPlan'));
            mItem.ActionAttribute = Enums.Menu.Action.FloorPlan;
            mItem.Icon = Enums.Menu.Icon.FloorPlan;
            mItem.IsAvailableInRecordingApp = typeof mItem.IsAvailableInRecordingApp === 'boolean' ?
                mItem.IsAvailableInRecordingApp :
                true;
            mItem.OnSelect = function() {
                Utils.Router.PushState(getBaseAddress() + 'floor-plan');
            };
        }

        // Information
        {
            const mItem = getMenuItem(Enums.Menu.SystemID.Information);
            mItem.Title = getMenuItemTitle(parsedConfig[Enums.Menu.SystemID.Information], i18next.t('Misc.Information_plural'));
            mItem.ActionAttribute = Enums.Menu.Action.Info;
            mItem.Icon = Enums.Menu.Icon.Info;
            mItem.IsAvailableInRecordingApp = typeof mItem.IsAvailableInRecordingApp === 'boolean' ?
                mItem.IsAvailableInRecordingApp :
                true;
            mItem.OnSelect = function() {
                if (Utils.InArray([Enums.View.Form, Enums.View.Inspection, Enums.View.Scheduling], View.CurrentView) && currentIssue) {
                    Utils.Router.PushState(getBaseAddress() + `information?type=${currentIssue.Type}`);
                } else {
                    Utils.Router.PushState(getBaseAddress() + 'information');
                }
            };
        }

        // Color & Custom Icon
        UpdateColorsAndIcon(parsedConfig);
    }

    export function UpdateColorsAndIcon(parsedConfig?) {
        parsedConfig = parsedConfig || menuItemsConfig;

        for (let key in parsedConfig) {
            const config = parsedConfig[key];
            const mItem = menuItems[config.ID];
            if (mItem) {
                if (Session.Settings.ShowMenuItemsAsTiles) {
                    mItem.BackgrountColor = config.Color || null;
                    mItem.TextColor = mItem.BackgrountColor ? new Model.Color(mItem.BackgrountColor).GetContrastColor().getHex() : null;
                } else {
                    mItem.TextColor = mItem.BackgrountColor = null;
                }
                mItem.Icon = config.IconOID || mItem.Icon;
            }
        }

        FitResizeMenu();
    }

    export function FitResizeMenu() {
        if (Session.Mode !== Enums.Mode.Menu ||
            !$content || !$preview) {
            return;
        }

        const MIN_PREVIEW_WIDTH = 300;
        const MIN_TILE_WIDTH = 125;
        const DEFAULT_TILE_WIDTH = 200;

        const availableWidth = $content.innerWidth();
        const tileWidth = Math.max(
            MIN_TILE_WIDTH,
            !Session.Settings.ShowMenuItemsAsTiles ?
                MIN_PREVIEW_WIDTH :
                $content.find('.menu-items li:first').outerWidth(true) || DEFAULT_TILE_WIDTH
        );
        const freeSpace = availableWidth - MIN_PREVIEW_WIDTH - tileWidth;   // min. Breite Vorschau => 300px & 1 Kachel

        if (freeSpace < 0) {
            $preview.css('display', 'none');
            return;
        } else {
            $preview.css('display', '');
        }

        const spaceWithoutPreview = freeSpace / 2.0 + tileWidth;    // freien Platz 1:1 teilen
        const numPossibleTiles = Math.floor(spaceWithoutPreview / tileWidth);   // Komplett sichtbare Kacheln berechnen
        const spaceForTiles = Math.max(1, numPossibleTiles) * tileWidth + 20;
        const spaceForPreview = Math.floor(availableWidth - spaceForTiles - 40);

        // Vorgangs Vorschau max 768px breit anzeigen
        $preview.css('width', Math.min(spaceForPreview, 768) + 'px');
    }

    function getDefaultIcon(mItemConfig: Model.Menu.IMenuItemConfig): Enums.Menu.Icon {
        switch (mItemConfig.ActionType) {
            case Enums.Menu.Action.Calendar:
                return Enums.Menu.Icon.Calendar;
            case Enums.Menu.Action.Parameters:
                return Enums.Menu.Icon.Parameters;
            case Enums.Menu.Action.Scheduling:
                return Enums.Menu.Icon.Scheduling;
            case Enums.Menu.Action.FormTemplates:
                return Enums.Menu.Icon.Forms;
            case Enums.Menu.Action.FloorPlan:
                return Enums.Menu.Icon.FloorPlan;
            case Enums.Menu.Action.Info:
                return Enums.Menu.Icon.Info;
            case Enums.Menu.Action.Link:
                return Enums.Menu.Icon.Link;
            case Enums.Menu.Action.IndividualData:
                return Enums.Menu.Icon.IndividualData;
            case Enums.Menu.Action.Issues:
                {
                    if (!mItemConfig.Configuration || !mItemConfig.Configuration.IssueTypes) {
                        return Enums.Menu.Icon.Issues;
                    }

                    const issueTypes = mItemConfig.Configuration.IssueTypes;

                    if ((issueTypes || []).length === 1) {
                        switch (issueTypes[0]) {
                            case Enums.IssueType.Task:
                            case Enums.IssueType.Inspection:
                            case Enums.IssueType.Form:
                            case Enums.IssueType.Survey:
                            case Enums.IssueType.Investigation:
                                return Enums.Menu.Icon.Tasks;
                            case Enums.IssueType.Scheduling:
                                return Enums.Menu.Icon.Scheduling;
                            case Enums.IssueType.Note:
                                return Enums.Menu.Icon.Notes;
                            case Enums.IssueType.Disturbance:
                                return Enums.Menu.Icon.Disturbances;
                        }
                    }

                    if (issueTypes.length > 1 &&
                        (!Utils.InArray(issueTypes, Enums.IssueType.Disturbance) &&
                            !Utils.InArray(issueTypes, Enums.IssueType.Note))) {
                        return Enums.Menu.Icon.Tasks;
                    }
                }

                return Enums.Menu.Icon.Issues;
        }
        return null;
    }

    function getMainMenuItems(counters: any, availableMenuItems: Array<any>): Array<Model.Menu.MenuItem> {
        // TODO set visibility by role
        const visibleMenuItems = [];
        const hasFiltersActive = IssueReport.AreFiltersActive();

        for (let key in menuItems) {
            const mItem = menuItems[key];

            if (!mItem) {
                continue;
            }

            // available since API 17
            const isAvailableInRecordingApp = typeof mItem.IsAvailableInRecordingApp === 'boolean' ? mItem.IsAvailableInRecordingApp : true;
            if (!isAvailableInRecordingApp) {
                continue;
            }

            const menuItemId = `${Enums.MenuSection.Content}_${mItem.ID}`;

            // update counter, marker
            switch (mItem.ActionAttribute) {
                case Enums.Menu.Action.Scheduling:
                    const count = getCountOfSchedulings(mItem.ID);

                    if (!getWhetherSchedulingTemplateButtonIsAvailable(menuItemId, availableMenuItems, count)) {
                        continue;
                    }

                    if (menuItems[mItem.ID]) {
                        menuItems[mItem.ID].CounterText = Utils.GetBadgeCounterText(count);
                    }
                    break;

                case Enums.Menu.Action.FloorPlan:
                    {
                        let location = Session.CurrentLocation;
                        let showFloorPlan = false;

                        do {
                            if (!!location.Layout) {
                                showFloorPlan = true;
                                break;
                            }
                        } while ((location = location.Parent));

                        if (!showFloorPlan) {
                            continue;
                        }
                    }
                    break;

                case Enums.Menu.Action.FormTemplates:
                    {
                        const location = Session.CurrentLocation;

                        if (!Utils.CanUserCreateIssueType(Enums.IssueType.Form, location) &&
                            !Utils.CanUserCreateIssueType(Enums.IssueType.Inspection, location)) {
                            continue;
                        }

                        const formCounter = getFormTemplateCounter(mItem.ID);

                        if (!formCounter.Total && !formCounter.AvailableFormsAtCurrentLocation) {
                            continue;
                        }

                        if (menuItems[mItem.ID]) {
                            menuItems[mItem.ID].CounterText = Utils.UserHasRight(Session.User.OID, Enums.Rights.SeeAllForms, true, location) ?
                                `${formCounter.AvailableFormsAtCurrentLocation} / ${formCounter.Total}` :
                                formCounter.AvailableFormsAtCurrentLocation;
                        }

                    }
                    break;

                case Enums.Menu.Action.Parameters:
                    if (menuItems[mItem.ID]) {
                        const count = getCountOfParameters(mItem.ID);
                        menuItems[mItem.ID].CounterText = Utils.GetBadgeCounterText(count);
                    }
                    break;
            }

            switch (mItem.ID) {
                case Enums.Menu.SystemID.Calendar:
                    // API < 8 hatte keine Einstellung für Dashboard
                    if (Session.LastKnownAPIVersion >= 8 &&
                        !Utils.InArray(availableMenuItems, `ContentMenu_${Enums.Menu.SystemID.Calendar}`)) {
                        continue;
                    }
                    break;

                case Enums.Menu.SystemID.IssuesReport:
                    // TODO uses Menu.SystemID
                    if (!Utils.InArray(availableMenuItems, Enums.MenuItemID.TaskReport) &&
                        !Utils.InArray(availableMenuItems, Enums.MenuItemID.NoteReport) &&
                        !Utils.InArray(availableMenuItems, Enums.MenuItemID.DisturbanceReport)) {
                        continue;
                    }

                    if (!Session.Settings.ShowAllIssuesInOneReport) {
                        continue;
                    }

                    if (menuItems[Enums.Menu.SystemID.IssuesReport]) {
                        menuItems[Enums.Menu.SystemID.IssuesReport].CounterText = Utils.GetBadgeCounterText(counters.IssueInformation.IssueCount);
                        menuItems[Enums.Menu.SystemID.IssuesReport].ItemMarker = counters.IssueInformation.IssueCellMarkerClass;
                        menuItems[Enums.Menu.SystemID.IssuesReport].ShowAddButton = false;
                    }
                    break;

                case Enums.Menu.SystemID.TaskReport:
                    if (Session.Settings.ShowAllIssuesInOneReport) {
                        continue;
                    }

                    // TODO uses Menu.SystemID
                    if (!Utils.InArray(availableMenuItems, Enums.MenuItemID.TaskReport)) {
                        continue;
                    }

                    if (menuItems[Enums.Menu.SystemID.TaskReport]) {
                        const tasks = counters.Tasks || {};
                        menuItems[Enums.Menu.SystemID.TaskReport].CounterText = Utils.GetBadgeCounterText(tasks.IssueCount);
                        menuItems[Enums.Menu.SystemID.TaskReport].ItemMarker = tasks.IssueCellMarkerClass;
                        menuItems[Enums.Menu.SystemID.IssuesReport].ShowAddButton = Utils.CanUserCreateIssueType(Enums.IssueType.Task, Session.CurrentLocation);
                    }
                    break;

                case Enums.Menu.SystemID.NoteReport:
                    if (Session.Settings.ShowAllIssuesInOneReport) {
                        continue;
                    }

                    // TODO uses Menu.SystemID
                    if (!Utils.InArray(availableMenuItems, Enums.MenuItemID.NoteReport)) {
                        continue;
                    }

                    if (menuItems[Enums.Menu.SystemID.NoteReport]) {
                        const notes = counters.Notes || {};
                        menuItems[Enums.Menu.SystemID.NoteReport].CounterText = Utils.GetBadgeCounterText(notes.IssueCount);
                        menuItems[Enums.Menu.SystemID.NoteReport].ItemMarker = notes.IssueCellMarkerClass;
                        menuItems[Enums.Menu.SystemID.NoteReport].ShowAddButton = Utils.CanUserCreateIssueType(Enums.IssueType.Note, Session.CurrentLocation);
                    }
                    break;

                case Enums.Menu.SystemID.DisturbanceReport:
                    if (Session.Settings.ShowAllIssuesInOneReport) {
                        continue;
                    }

                    // TODO uses Menu.SystemID
                    if (!Utils.InArray(availableMenuItems, Enums.MenuItemID.DisturbanceReport)) {
                        continue;
                    }

                    if (menuItems[Enums.Menu.SystemID.DisturbanceReport]) {
                        const disturbances = counters.Disturbances || {};
                        menuItems[Enums.Menu.SystemID.DisturbanceReport].CounterText = Utils.GetBadgeCounterText(disturbances.IssueCount);
                        menuItems[Enums.Menu.SystemID.DisturbanceReport].ItemMarker = disturbances.IssueCellMarkerClass;
                        menuItems[Enums.Menu.SystemID.DisturbanceReport].ShowAddButton = Utils.CanUserCreateIssueType(Enums.IssueType.Disturbance, Session.CurrentLocation);
                    }
                    break;

                case Enums.Menu.SystemID.Scheduling:
                    // TODO uses Menu.SystemID
                    if (!Utils.InArray(availableMenuItems, Enums.MenuItemID.Scheduling)) {
                        continue;
                    }
                    break;

                case Enums.Menu.SystemID.FormTemplates:
                    // TODO uses Menu.SystemID
                    if (!Utils.InArray(availableMenuItems, Enums.MenuItemID.FormTemplates)) {
                        continue;
                    }
                    break;

                case Enums.Menu.SystemID.Parameters:
                    // TODO uses Menu.SystemID
                    if (!Utils.InArray(availableMenuItems, Enums.MenuItemID.Parameters)) {
                        continue;
                    }
                    break;

                case Enums.Menu.SystemID.Information:
                    // TODO uses Menu.SystemID
                    if (!Utils.InArray(availableMenuItems, Enums.MenuItemID.Information)) {
                        continue;
                    }
                    break;

                case Enums.Menu.SystemID.FloorPlan:
                    {
                        // TODO uses Menu.SystemID
                        if (!Utils.InArray(availableMenuItems, Enums.MenuItemID.FloorPlan)) {
                            continue;
                        }
                    }
                    break;

                default:
                    // check in availableMenuItems
                    if (!Utils.InArray(availableMenuItems, `ContentMenu_${mItem.ID}`, true)) {
                        continue;
                    }

                    if (mItem.ActionAttribute == Enums.Menu.Action.Issues) {
                        if (menuItems[mItem.ID]) {
                            let state: Model.Issues.Counter;
                            if (Session.IsSmartDeviceApplication) {
                                const tCache = DAL.TreeCache.Custom[mItem.ID];
                                if (tCache) {
                                    // set counter by TreeCache / MenuItem settings
                                    const node = tCache.getNode(Session.CurrentLocation.OID);
                                    if (!node) {
                                        continue;
                                    }
                                    state = node.getTotalStateCounter();
                                }
                            } else if (counters.Custom) {
                                state = counters.Custom[mItem.ID];
                            }

                            state = state || new Model.Issues.Counter();
                            if (state.IssueCount) {
                                menuItems[mItem.ID].CounterText = Utils.GetBadgeCounterText(state.IssueCount);
                                menuItems[mItem.ID].ItemMarker = state.IssueCellMarkerClass;
                            } else {
                                menuItems[mItem.ID].CounterText = 0;
                                menuItems[mItem.ID].ItemMarker = Enums.IssueCellMarker.Green;
                            }
                        }
                    }
            }

            // hide menu items when empty
            if (mItem.ActionAttribute == Enums.Menu.Action.Parameters ||
                mItem.ActionAttribute == Enums.Menu.Action.Issues) {
                if (!(+mItem.CounterText)) {
                    const config = GetMenuItemConfig(mItem.ID);
                    if (config && config.Configuration && config.Configuration.HideWhenEmpty) {
                        // do not add to visible menu items
                        continue;
                    }
                }
            }

            // add to visible items list
            visibleMenuItems.push(mItem);

            // update filter state
            if (mItem.ActionAttribute == Enums.Menu.Action.Issues) {
                mItem.HasFilterActive = hasFiltersActive;
            }
        }

        // sort menu items
        const menuItemsOrder = Utils.GetAvailableContentMenuItems(Enums.MenuSection.Content, Session.CurrentLocation.OID);
        visibleMenuItems.sort(function(a, b) {
            const posA = (menuItemsOrder[a.ID] || { Position: 0 }).Position || 0;
            const posB = (menuItemsOrder[b.ID] || { Position: 0 }).Position || 0;
            return posA - posB;
        });

        return visibleMenuItems;
    }

    export function GetFilter(item: Model.Menu.IMenuItemConfig): Model.Issues.IFilter {
        let tmpFilter: Model.Issues.Filter = null;
        let types: Utils.HashSet;
        if (item && item.Configuration) {
            let formFilter = null;
            let schedulingsFilter = null;

            if (!(item.Configuration.IssueTypes || []).length ||
                Utils.InArray(item.Configuration.IssueTypes, Enums.IssueType.Form) ||
                Utils.InArray(item.Configuration.IssueTypes, Enums.IssueType.Survey) ||
                Utils.InArray(item.Configuration.IssueTypes, Enums.IssueType.Investigation)) {
                formFilter = item.Configuration.Forms;
            }
            if (!(item.Configuration.IssueTypes || []).length ||
                Utils.InArray(item.Configuration.IssueTypes, Enums.IssueType.Scheduling)) {
                schedulingsFilter = item.Configuration.Schedulings;
            }

            tmpFilter = <Model.Issues.Filter>{
                Classifications: item.Configuration.Classifications,
                Contacts: item.Configuration.Contacts,
                ContactGroups: item.Configuration.ContactGroups,
                Forms: formFilter,
                Keywords: item.Configuration.Keywords,
                Priorities: item.Configuration.Priorities,
                ProcessingStatus: item.Configuration.ProcessingStatuses,
                Schedulings: schedulingsFilter,
                States: item.Configuration.Statuses,
                Teams: item.Configuration.Teams,
                Users: item.Configuration.Users,
                IncludeDerivations: item.Configuration.DisplayAsInfoBoard || false
            };

            types = new Utils.HashSet(item.Configuration.IssueTypes);

            if (item.IsSystemEntity) {
                if (Session.Client.Licenses.Inspections &&
                    Utils.InArray(item.Configuration.IssueTypes, Enums.IssueType.Inspection)) {
                    types.put(Enums.IssueType.Inspection);
                }

                if (Session.Client.Settings.SyncOpenSurveys &&
                    Utils.InArray(item.Configuration.IssueTypes, Enums.IssueType.Survey)) {
                    types.put(Enums.IssueType.Survey);
                }

                if (Session.Client.Settings.SyncOpenInvestigations &&
                    Utils.InArray(item.Configuration.IssueTypes, Enums.IssueType.Investigation)) {
                    types.put(Enums.IssueType.Investigation);
                }
            }
        }

        if (!types || !types.size()) {
            // bei nicht eingeschränktem Type Filter, alle Typen anzeigen
            types = new Utils.HashSet([
                Enums.IssueType.Task,
                Enums.IssueType.Scheduling,
                Enums.IssueType.Form,
                Enums.IssueType.Note,
                Enums.IssueType.Disturbance
            ]);

            if (Session.Client.Licenses.Inspections) {
                types.put(Enums.IssueType.Inspection);
            }

            if (Session.Client.Settings.SyncOpenSurveys) {
                types.put(Enums.IssueType.Survey);
            }

            if (Session.Client.Settings.SyncOpenInvestigations) {
                types.put(Enums.IssueType.Investigation);
            }
        } else {
            // Issue-Typen ohne Lizenz entfernen
            if (!Session.Client.Licenses.Inspections) {
                types.delete(Enums.IssueType.Inspection);
            }

            if (!Session.Client.Settings.SyncOpenSurveys) {
                types.delete(Enums.IssueType.Survey);
            }

            if (!Session.Client.Settings.SyncOpenInvestigations) {
                types.delete(Enums.IssueType.Investigation);
            }
        }

        if (types && types.size()) {
            tmpFilter = tmpFilter || <Model.Issues.Filter>{};
            tmpFilter.Types = <Enums.IssueType[]>types.toIntArray();
        }

        return tmpFilter;
    }

    export function GetCustomMenuItemsConfig(): Array<Model.Menu.IMenuItemConfig> {
        const resultItems = [];
        for (let i = 0; i < menuItemsConfig.length; i++) {
            const mItem = menuItemsConfig[i];
            if (mItem.IsSystemEntity || mItem.Deleted) {
                continue;
            }

            resultItems.push(mItem);
        }
        return resultItems;
    }

    export function GetMenuItemConfig(id: string): Model.Menu.IMenuItemConfig {
        if (!id) {
            return null;
        }

        for (let i = 0; i < menuItemsConfig.length; i++) {
            const mItem = menuItemsConfig[i];

            if (id && mItem && mItem.ID &&
                mItem.ID.toLowerCase() == id.toLowerCase()) {
                return mItem;
            }
        }
        return null;
    }

    export function HasConfig(): boolean {
        return menuItemsConfig && !!menuItemsConfig.length;
    }

    export function HasMenuItems(): boolean {
        if (menuItems) {
            for (let _key in menuItems) {
                return true;
            }
        }
        return false;
    }

    export function Reset() {
        menuItems = {};
        menuItemsConfig = [];
        visibleMenuItems = [];
    }

    $backButton.on('click', onBackButtonClick);
}