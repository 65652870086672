//imports-start
/// <reference path="../definitions.d.ts" />
/// <reference path="./utils.http.ts" />
/// <reference path="../config.ts" />
//imports-end

module Utils.CredentialEditor {
    const zIndex = 30000;

    let _$overlay, _$win;
    let _$username, _$password;
    let _$btnAbort, _$btnSave;
    let _$btnLogout;

    let _username: string, _password: string;
    let _previousAuthHash: string;
    let _isForced: boolean;
    let _callback: () => void;

    function onUsernameInput() {
        _username = $.trim($(this).val());

        if (!!_username && !!_password) {
            _$btnSave.removeAttr('disabled');
        } else {
            _$btnSave.attr('disabled', 'disabled');
        }
    }

    function onPasswordInput() {
        _password = $(this).val();

        if (!!_username && !!_password) {
            _$btnSave.removeAttr('disabled');
        } else {
            _$btnSave.attr('disabled', 'disabled');
        }
    }

    function onUsernameKeyPress(evt) {
        if (evt.keyCode === Enums.KeyCode.RETURN) {
            evt.preventDefault();
            _$password.focus();
        }
    }

    function onPasswordKeyPress(evt) {
        if (evt.keyCode === Enums.KeyCode.RETURN) {
            _$btnSave.click();
        }
    }

    function onBtnAbortClick() {
        if ($(this).attr('disabled')) {
            return;
        }

        destroy();
    }

    function onBtnSaveClick() {
        if ($(this).attr('disabled')) {
            return;
        }

        const sessionPreparation = !Session.IsSmartDeviceApplication || Session.LastKnownAPIVersion >= 21 ?
            beforeGetAccount() :
            $.Deferred().resolve();

        Utils.Spinner.Show(_$win.get(0));

        sessionPreparation
            .then(tryGetAccount)
            .then(checkForCorrectAccount)
            .always(function() {
                Utils.Spinner.Hide();
            })
            .then(onAfterCredentialsChanged, onServerError);
    }

    function beforeGetAccount(): Deferred {
        if (Session.IsSmartDeviceApplication && Session.LastKnownAPIVersion < 21) {
            Session.AuthHash = `${_username}:${_password}`.toBase64();
            return $.Deferred().resolve().promise();
        }

        const remember = Session.IsSmartDeviceApplication ||
            /true/i.test(Utils.Cookies.Get(Session.CookieConfig.RememberCookieName));

        const application = Session.IsSmartDeviceApplication ? 'App' : 'Web';

        const loginData: Model.LoginData = {
            Username: _username,
            Password: _password,
            Remember: remember,
            Application: application
        };

        return Utils.Http.Post('login', loginData, null, true, null, Utils.Http.TIMEOUT_MS_SHORT, false)
            .then((result, status, xhr) => {
                Session.SetCookieConfig(xhr);
            }, (xhr) => {
                switchToForcedMode();
                return xhr;
            });
    }

    function tryGetAccount(): Deferred {
        return Utils.Http.Get('accounts', null, true);
    }

    function onAfterCredentialsChanged(): void {
        const callback = _callback;

        destroy();

        if (callback && callback instanceof Function) {
            callback();
        }
    }

    function onServerError(xhr, status: string, errorText: string) {
        const messageButtonSettings: Utils.Message.OptionButtons = {
            Close: true,
            OnHide: true
        };

        if (xhr && xhr.status === Enums.HttpStatusCode.Unauthorized) {
            Utils.Message.Show(
                i18next.t('Settings.WrongCredentials.MessageHeader'),
                i18next.t('Settings.WrongCredentials.MessageBody'),
                messageButtonSettings,
                null,
                zIndex + 10
            );
        } else if (xhr && xhr.status === Enums.HttpStatusCode.Forbidden) {
            Utils.Message.Show(
                i18next.t('Login.LoginFailed.MessageHeader'),
                i18next.t('Login.LoginFailed.LoginNotAllowed'),
                messageButtonSettings,
                null,
                zIndex + 10
            );
        } else {
            Utils.Message.Show(
                i18next.t('Settings.CouldNotVerifyCredentials.MessageHeader'),
                i18next.t('Settings.CouldNotVerifyCredentials.MessageBody'),
                messageButtonSettings,
                null,
                zIndex + 10
            );
        }

        // Fehler loggen
        SyncCenter.SaveError(new Model.Errors.HttpError((xhr ? xhr.responseText : null) || errorText, xhr));

        resetAuth();

        return $.Deferred().reject().promise();
    }

    function onBtnLogoutClick() {
        if ($(this).attr('disabled')) {
            return;
        }

        Utils.Message.Show(
            i18next.t('Logout.MessageHeader'),
            i18next.t('Logout.MessageBody'),
            {
                Yes: {
                    Caption: i18next.t('Logout.ConfirmationButtonCaption'),
                    Classes: ['btn', 'flat', 'btn-danger', 'btn-yes'],
                    Fn: () => {
                        destroy();
                        App.Logout();
                    }
                },
                No: {
                    Classes: ['btn', 'flat', 'btn-success', 'btn-no']
                }
            },
            null,
            zIndex + 10
        );
    }

    function onTabFocus() {
        const currentSessionUser = Utils.Cookies.GetActiveSessionUser();

        if (!currentSessionUser) {
            switchToForcedMode();
            return;
        }

        if (currentSessionUser === (Session.User || { OID: null }).OID) {
            // Nur automatisch das Fenster schließen, wenn vorher die Zugangsdaten ungültig waren
            if (_isForced) {
                tryGetAccount()
                    .then(checkForCorrectAccount)
                    .then(onAfterCredentialsChanged)
                    .fail(function(_response, _state, _error) {
                        throw new Model.Errors.HttpError(_error, _response);
                    });
            }
        } else {
            // Benutzer wurde in einem anderen Tab geändert
            location.reload();
        }
    }

    function checkForCorrectAccount(account): Deferred {
        const deferred = $.Deferred();

        if (account.User.OID !== Session.User.OID) {
            resetAuth()
                .always(() => {
                    Utils.Message.Show(i18next.t('Settings.CannotChangeUserOnTheFly.MessageHeader'),
                        i18next.t('Settings.CannotChangeUserOnTheFly.MessageBody'),
                        {
                            Close: () => { deferred.reject() }
                        },
                        null,
                        zIndex + 10
                    );
                });
        } else {
            saveCredentials();
            deferred.resolve();
        }

        return deferred.promise();
    }

    function saveCredentials() {
        if (Session.IsSmartDeviceApplication && Session.LastKnownAPIVersion < 21) {
            window.Database.SetInStorageNoChecks(Enums.DatabaseStorage.SystemData, {
                User: Session.User,
                Client: Session.Client,
                Settings: Session.Settings,
                BaseURI: Session.BaseURI
            });
        }

        _previousAuthHash = null;
    }

    function resetAuth(): Deferred {
        if (Session.IsSmartDeviceApplication && Session.LastKnownAPIVersion < 21) {
            Session.AuthHash = _previousAuthHash;
            _previousAuthHash = null;

            return $.Deferred().resolve().promise();
        }

        return Utils.Http.Post('logout', {}, null, true)
            .then(null, Utils.Http.HandleLogoutError)
            .always(() => {
                if (Session.IsSmartDeviceApplication) {
                    cordova.plugin.http.clearCookies();
                }
            });
    }

    function destroy() {
        if (_$win) {
            _$win.remove();
            _$win = null;
        }

        if (_$overlay) {
            Utils.Overlay.DestroyWithTimeout(_$overlay);
            _$overlay = null;
        }

        _$username = null;
        _$password = null;
        _$btnAbort = null;
        _$btnSave = null;
        _$btnLogout = null;

        _username = null;
        _password = null;

        _isForced = null;
        _callback = null;

        if (!Session.IsSmartDeviceApplication) {
            $(window).off('focus.sessionCredentialEditor');
            App.Init.InitWindowFocusEvent();
        }

        if (!Utils.RecorditemEditor.IsVisible() && !Utils.IssueViewer.IsVisible()) {
            $('body').removeClass('modal-open');
        }
    }

    function initVariables(additionalInformation?: string, isForced?: boolean, callback?: () => void) {
        _$overlay = Utils.Overlay.Generate('olCredentialEditor', zIndex - 1, isForced ? null : destroy);
        _$win = $(Templates.CredentialEditor({
            AdditionalInformation: additionalInformation,
            Username: Session.User.Username,
            CustomZIndex: zIndex
        }));

        _$username = _$win.find('[data-id="username"]');
        _$password = _$win.find('[data-id="password"]');

        _$btnAbort = _$win.find('.btn-abort');
        _$btnSave = _$win.find('.btn-success');
        _$btnLogout = _$win.find('.btn-logout');

        _username = Session.User.Username;

        if (Session.IsSmartDeviceApplication && Session.LastKnownAPIVersion < 21) {
            _previousAuthHash = Session.AuthHash;
        }

        _isForced = isForced;
        _callback = callback;

        _$btnAbort.toggleClass('hidden', !!isForced);
        _$btnLogout.toggleClass('hidden', !isForced);
    }

    function bindEvents() {
        _$username.on('input', onUsernameInput);
        _$password.on('input', onPasswordInput);
        _$username.on('keypress', onUsernameKeyPress);
        _$password.on('keypress', onPasswordKeyPress);
        _$btnAbort.on('click', onBtnAbortClick);
        _$btnSave.on('click', onBtnSaveClick);
        _$btnLogout.on('click', onBtnLogoutClick);

        if (!Session.IsSmartDeviceApplication) {
            //focus event des tabs nur in diesem Editor berücksichtigen
            App.Init.UnbindWindowFocusEvent();
            $(window).on('focus.sessionCredentialEditor', onTabFocus);
        }
    }

    function showWindow() {
        $('body').addClass('modal-open').append(_$win);
        Utils.RepositionNewModalWindow(_$win);

        if (!Session.IsRunningOnIOS) {
            _$password.focus();
        }
    }

    /**
     * Der Editor kann nicht mehr durch den Benutzer geschlossen werden, bis gültige Zugangsdaten eingegeben wurden
     * @private
     */
    function switchToForcedMode() {
        _isForced = true;
        _$btnAbort.toggleClass('hidden', true);
        _$btnLogout.toggleClass('hidden', false);
        _$overlay.off('click');
    }

    /**
     *
     * @param additionalInformation Zusätzlicher Text der im Dialog angezeigt wird
     * @param isForced - Das Fenster kann es geschlossen werden, wenn gültige Zugangsdaten hinterlegt wurden
     * @param callback - Wird aufgerufen, sobald die Zugangsdaten erfolgreich hinterlegt wurden
     * @constructor
     */
    export function Show(additionalInformation?: string, isForced?: boolean, callback?: () => void) {
        initVariables(additionalInformation, isForced, callback);
        showWindow();
        bindEvents();
    }
}
