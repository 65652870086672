//imports-start
/// <reference path="../definitions.d.ts"  />
/// <reference path="../app/app.session.ts"  />
/// <reference path="./utils.input-window.ts"  />
/// <reference path="./utils.http.ts"  />
//imports-end

module Utils.PasswordEditor {
    let _user: any;

    let _$body;
    let _$overlay, _$win, _$policyList;
    let _$currentPassword, _$newPassword, _$newPasswordConfirmation;
    let _$pwStrengthIndicator;
    let _$btnSave;

    let _passwordChangedDeferred;

    function onPasswordChange(): void {
        const currentPassword = _$currentPassword.val() || '';
        let password: string = _$newPassword.val();
        let confirmation: string = _$newPasswordConfirmation.val();
        let checkResult = checkPolicies(currentPassword, password, confirmation);
        let passedRequirements: number = 0;

        // Richtlinien die keinen direkten Einfluss auf die Stärke des Passwortes haben, aber erforderlich sind
        let enableSaveButton = checkResult.MatchesConfirmation &&
            checkResult.CurrentPassword &&
            checkResult.DoesNotContainEachOther;

        _$policyList.find('.success').removeClass('success');

        if (checkResult.DoesNotContainEachOther) {
            _$policyList.find('li[data-policy="does-not-contain-each-other"]').addClass('success');
        }

        if (checkResult.MatchesConfirmation) {
            _$policyList.find('li[data-policy="matches-confirmation"]').addClass('success');
        }

        if (_user.EnforcePasswordPolicy) {
            enableSaveButton = enableSaveButton &&
                checkResult.IsLengthSufficient &&
                checkResult.ContainsLowerCaseLetters &&
                checkResult.ContainsUpperCaseLetters &&
                checkResult.ContainsDigits &&
                checkResult.ContainsSpecialCharacters;
        }

        if (checkResult.IsLengthSufficient) {
            _$policyList.find('li[data-policy="is-length-sufficient"]').addClass('success');
            passedRequirements++;
        }

        if (checkResult.ContainsLowerCaseLetters) {
            _$policyList.find('li[data-policy="contains-lowercase-letters"]').addClass('success');
            passedRequirements++;
        }

        if (checkResult.ContainsUpperCaseLetters) {
            _$policyList.find('li[data-policy="contains-uppercase-letters"]').addClass('success');
            passedRequirements++;
        }

        if (checkResult.ContainsDigits) {
            _$policyList.find('li[data-policy="contains-digits"]').addClass('success');
            passedRequirements++;
        }

        if (checkResult.ContainsSpecialCharacters) {
            _$policyList.find('li[data-policy="contains-special-characters"]').addClass('success');
            passedRequirements++;
        }

        if (checkResult.HasExtendedLength) {
            passedRequirements++;
        }

        // don't allow username as password
        if (_user.Username == password) {
            enableSaveButton = false;
            passedRequirements = 0;
        }

        // set indicator
        let text, backgroundColor;
        if (!password || !password.length) {
            backgroundColor = '#fff';
            text = i18next.t('PasswordEditor.Complexity.NoPassword');
            enableSaveButton = false;
        } else if (passedRequirements == 0) {
            backgroundColor = '#FF5252';
            text = i18next.t('PasswordEditor.Complexity.VeryWeak');
            enableSaveButton = false;
        } else if (passedRequirements >= 6) {
            backgroundColor = '#27AE60';
            text = i18next.t('PasswordEditor.Complexity.VeryStrong');
        } else if (passedRequirements >= 5) {
            backgroundColor = '#d4edda';
            text = i18next.t('PasswordEditor.Complexity.Strong');
        } else if (passedRequirements >= 3 && checkResult.IsLengthSufficient) {
            backgroundColor = '#F5D76E';
            text = i18next.t('PasswordEditor.Complexity.Medium');
            enableSaveButton = false;
        } else if (passedRequirements > 2) {
            backgroundColor = '#FF5722';
            text = i18next.t('PasswordEditor.Complexity.Weak');
            enableSaveButton = false;
        } else {
            backgroundColor = '#FF5252';
            text = i18next.t('PasswordEditor.Complexity.VeryWeak');
            enableSaveButton = false;
        }

        const textColor = new Model.Color(backgroundColor)
            .GetContrastColor()
            .getHex();

        _$pwStrengthIndicator
            .css({
                'background-color': backgroundColor,
                color: textColor
            })
            .text(text);

        if (enableSaveButton) {
            _$btnSave.removeAttr('disabled');
        } else {
            _$btnSave.attr('disabled', 'disabled');
        }
    }

    function onBtnSaveClick(): void {
        if ($(this).attr('disabled')) {
            return;
        }

        const currentPassword = _$currentPassword.val();
        const pw = _$newPassword.val();

        Utils.CheckIfDeviceIsOnline()
            .then(function() {
                Session.RefreshServerSession()
                    .then(() => {
                        Utils.Http
                            .Post('users', { Action: 'SetPassword', CurrentPassword: currentPassword, Password: pw }, null, true)
                            .then(function() {
                                _passwordChangedDeferred.resolve(pw);
                                destroy();
                            }, function(_response, _state, _error) {
                                if (!_response) {
                                    throw new Model.Errors.HttpError(_error, _response);
                                }

                                let messageHeaderKey: string;
                                let messageBodyKey: string, messageBodyVariable: Dictionary<any> = {};

                                switch (_response.status) {
                                    case Enums.HttpStatusCode.Unauthorized:
                                        messageHeaderKey = 'PasswordEditor.InvalidCredentials.MessageHeader';
                                        messageBodyKey = 'PasswordEditor.InvalidCredentials.MessageBody';
                                        break;
                                    case Enums.HttpStatusCode.Bad_Request:
                                        switch (_response.responseText) {
                                            case 'The required password policy has not been fulfilled':
                                                messageHeaderKey = 'PasswordEditor.PolicyNotFulfilled.MessageHeader';
                                                messageBodyKey = 'PasswordEditor.PolicyNotFulfilled.MessageBody';
                                                break;
                                            default:
                                                messageHeaderKey = 'PasswordEditor.Unknown.MessageHeader';
                                                messageBodyKey = 'PasswordEditor.Unknown.MessageBody';
                                                messageBodyVariable.Code = _response.status;
                                                messageBodyVariable.Message = _response.responseText;
                                                break;
                                        }
                                        break;
                                    default:
                                        messageHeaderKey = 'PasswordEditor.Unknown.MessageHeader';
                                        messageBodyKey = 'PasswordEditor.Unknown.MessageBody';
                                        messageBodyVariable.Code = _response.status;
                                        messageBodyVariable.Message = _response.responseText;
                                        break;
                                }

                                Utils.Message.Show(i18next.t(messageHeaderKey),
                                    i18next.t(messageBodyKey, messageBodyVariable),
                                    {
                                        Close: true
                                    },
                                    null, 20020);
                                _passwordChangedDeferred.reject();

                                return;
                            });
                    }, function(_response, _state, _error) {
                        throw new Model.Errors.HttpError(_error, _response);
                    });
            }, function() {
                Utils.Message.Show(i18next.t('PasswordEditor.NoInternetConnection.MessageHeader'),
                    i18next.t('Misc.NoInternetConnection.MessageBody'),
                    {
                        Close: true
                    }, null, 20020);

                _passwordChangedDeferred.reject();
            });
    }

    function onCurrentPasswordKeyPress(evt): void {
        if (evt.keyCode === Enums.KeyCode.RETURN) {
            _$newPassword.focus();
            return;
        }
    }

    function onPasswordKeyPress(evt): void {
        if (evt.keyCode === Enums.KeyCode.RETURN) {
            _$newPasswordConfirmation.focus();
            return;
        }
    }

    function onPasswordConfirmationKeyPress(evt): void {
        if (evt.keyCode === Enums.KeyCode.RETURN) {
            onBtnSaveClick.call(_$btnSave);
            return;
        }
    }

    function destroy() {
        if (_$overlay) {
            Utils.Overlay.DestroyWithTimeout(_$overlay);
            _$overlay = null;
        }

        if (_$win) {
            _$win.remove();
            _$win = null;
        }

        _$body.removeClass('modal-open');

        _user = null;
        _$policyList = null;
        _$currentPassword = null;
        _$newPassword = null;
        _$newPasswordConfirmation = null;
        _$pwStrengthIndicator = null;
        _$btnSave = null;
        _passwordChangedDeferred = null;

        App.Init.InitWindowFocusEvent();
    }

    function checkPolicies(currentPassword?: string, password?: string, confirmation?: string) {
        const result = {
            CurrentPassword: false,
            DoesNotContainEachOther: false,
            MatchesConfirmation: false,
            IsLengthSufficient: false,
            HasExtendedLength: false,
            ContainsLowerCaseLetters: false,
            ContainsUpperCaseLetters: false,
            ContainsDigits: false,
            ContainsSpecialCharacters: false
        };

        if (!password) {
            return result;
        }

        result.CurrentPassword = (currentPassword || '').trim().length > 0;
        result.DoesNotContainEachOther = !result.CurrentPassword || (!password.toLowerCase().includes(currentPassword.toLowerCase()) && !currentPassword.toLowerCase().includes(password.toLowerCase()));
        result.MatchesConfirmation = password === confirmation;
        result.IsLengthSufficient = password.length >= 8;
        result.HasExtendedLength = password.length >= 10;
        result.ContainsLowerCaseLetters = /[a-z\u00E4\u00F6\u00FC\u00DF]/.test(password);
        result.ContainsUpperCaseLetters = /[A-Z\u00C4\u00D6\u00DC]/.test(password);
        result.ContainsDigits = /\d/.test(password);
        result.ContainsSpecialCharacters = /[!@#$%^&*()_+{}:"|~<>?\-=[\];'\\\,.\/]/.test(password);

        return result;
    }

    function bindEvents() {
        _$currentPassword.on('change input', onPasswordChange);
        _$currentPassword.on('keypress', onCurrentPasswordKeyPress);
        _$newPassword.on('change input', onPasswordChange);
        _$newPassword.on('keypress', onPasswordKeyPress);
        _$newPasswordConfirmation.on('change input', onPasswordChange);
        _$newPasswordConfirmation.on('keypress', onPasswordConfirmationKeyPress);
        _$btnSave.on('click', onBtnSaveClick);
    }

    export function Show(user) {
        if (!Session.IsSmartDeviceApplication) {
            // Verhindern das die Erfassung neu geladen wird, wenn der Fokus gewechselt wird
            App.Init.UnbindWindowFocusEvent();
        }

        _$body = $('body');
        _user = user;

        _$overlay = Utils.Overlay.Generate('olPasswordEditor', 20014);
        _$win = $(Templates.PasswordEditor({
            Policies: checkPolicies(),
            EnforcePasswordPolicy: _user.EnforcePasswordPolicy
        }));

        _$currentPassword = _$win.find('input[data-id="current-password"]');
        _$newPassword = _$win.find('input[data-id="password"]');
        _$newPasswordConfirmation = _$win.find('input[data-id="password-confirmation"]');
        _$pwStrengthIndicator = _$win.find('.strength-indicator');
        _$policyList = _$win.find('ul');
        _$btnSave = _$win.find('.btn-success');

        _passwordChangedDeferred = $.Deferred();

        _$body.addClass('modal-open').append(_$win);

        if (!Session.IsRunningOnIOS) {
            _$currentPassword.focus();
        }

        bindEvents();

        Utils.RepositionNewModalWindow(_$win);

        return _passwordChangedDeferred;
    };
}
