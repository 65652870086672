//imports-start
/// <reference path="../definitions.d.ts"  />
/// <reference path="./model.errors.ts"  />
/// <reference path="./model.comment.ts"  />
/// <reference path="../dal/issues.ts"  />
/// <reference path="../utils/utils.date-time.ts"  />
//imports-end

module Model.Synchronisation {
    export type OnAfterUploadFunc = (serviceEntity: any, storageName: string, id: number, syncEntity: Model.Synchronisation.IEntityDescription) => void;
    export type OnAfterDeleteCommentFunc = (syncEntity: Model.Synchronisation.IEntityDescription) => void;
    export type OnAfterFileUploadFunc = (syncEntity: Model.Synchronisation.IEntityDescription) => void;
    export type OnCommentEntityNotFoundErrorFunc = (syncEntity: Model.Synchronisation.IEntityDescription) => void;
    export type OnDeleteCommentErrorFunc = (xhr, status: string, errorText: string, syncEntity: Model.Synchronisation.IEntityDescription) => void;
    export type OnEntityNotFoundErrorFunc = (syncEntity: Model.Synchronisation.IEntityDescription) => void;
    export type OnUploadErrorFunc = (xhr, status: string, errorText: string, serviceEntity: any, syncEntity: Model.Synchronisation.IEntityDescription, request: any) => void;

    export interface IEntityDescription {
        ID?: number;
        OID: string;
        Type: Enums.SyncEntityType;
        Timestamp: string;
        DependingOnOID: string;
        DependingOnOIDs?: string[];
        ParentOID: string;
        PrecedingOID: string;
        IsErroneous: boolean;
        Ignore: boolean;
        IssueID?: number;
        IssueOID?: string;

        /* internal use only */
        Dependencies: Utils.IndexedArray<IEntityDescription>;
        /* internal use only - end */

        GetTypeString(): string | null;

        OnAfterUpload: OnAfterUploadFunc;
        OnUploadError: OnUploadErrorFunc;
        OnAfterDeleteComment: OnAfterDeleteCommentFunc;
        OnDeleteCommentError: OnDeleteCommentErrorFunc;
        OnEntityNotFoundError: OnEntityNotFoundErrorFunc;
        OnCommentEntityNotFoundError: OnCommentEntityNotFoundErrorFunc;

        SetOnAfterUploadHandler(onAfterUpload: OnAfterUploadFunc): IEntityDescription;
        SetOnUploadErrorHandler(onUploadError: OnUploadErrorFunc): IEntityDescription;
        SetOnEntityNotFoundErrorHandler(onEntityNotFoundError: OnEntityNotFoundErrorFunc): IEntityDescription;
        SetOnAfterDeleteCommentHandler(onAfterDeleteComment: OnAfterDeleteCommentFunc): IEntityDescription;
        SetOnDeleteCommentErrorHandler(onDeleteCommentError: OnDeleteCommentErrorFunc): IEntityDescription;
        SetOnCommentEntityNotFoundErrorHandler(onCommentEntityNotFoundError: OnCommentEntityNotFoundErrorFunc): IEntityDescription;

        GetDependencyOIDs(): string[];
        AddDependency(dependency: IEntityDescription): void;
        Upload(): Deferred;
    }

    export interface IWithGetServiceEntity extends IEntityDescription {
        GetServiceEntity(linkUploadDependency?: boolean, supportMailOption?: boolean): Deferred;
    }

    export function GetTypeString(type: Enums.SyncEntityType): string | null {
        switch (type) {
            case Enums.SyncEntityType.Issue:
            case Enums.SyncEntityType.SubIssueCounter:
            case Enums.SyncEntityType.SubSampleUpdate:
            case Enums.SyncEntityType.SubSampleDelete:
            case Enums.SyncEntityType.RecordingLockState:
                return i18next.t('Synchronization.SyncEntityType.Issue');
            case Enums.SyncEntityType.IssueFile:
                return i18next.t('Synchronization.SyncEntityType.IssueFile');
            case Enums.SyncEntityType.Recorditem:
            case Enums.SyncEntityType.RecorditemDelete:
                return i18next.t('Synchronization.SyncEntityType.Recorditem');
            case Enums.SyncEntityType.RecorditemAdditionalFile:
            case Enums.SyncEntityType.RecorditemValueFile:
                return i18next.t('Synchronization.SyncEntityType.RecorditemFile');
            case Enums.SyncEntityType.IssueComment:
            case Enums.SyncEntityType.RecorditemComment:
            case Enums.SyncEntityType.IssueDeleteComment:
            case Enums.SyncEntityType.RecorditemDeleteComment:
                return i18next.t('Synchronization.SyncEntityType.Comment');
            case Enums.SyncEntityType.InspectionCounter:
                return i18next.t('Synchronization.SyncEntityType.Element');
            default:
                return null;
        }
    }

    abstract class EntityDescriptionBase implements IEntityDescription {
        public ID?: number;
        public OID: string;
        public Type: Enums.SyncEntityType;
        public Timestamp: string;
        public DependingOnOID: string;
        public ParentOID: string;
        public PrecedingOID: string;
        public IsErroneous: boolean;
        public Ignore: boolean;
        public IssueID?: number;
        public IssueOID?: string;

        /* internal use only */
        Dependencies: Utils.IndexedArray<IEntityDescription>;
        /* internal use only - end */

        public OnAfterUpload: OnAfterUploadFunc;
        public OnUploadError: OnUploadErrorFunc;
        public OnAfterDeleteComment: OnAfterDeleteCommentFunc;
        public OnDeleteCommentError: OnDeleteCommentErrorFunc;
        public OnEntityNotFoundError: OnEntityNotFoundErrorFunc;
        public OnCommentEntityNotFoundError: OnCommentEntityNotFoundErrorFunc;

        public Init(obj: any)
        public Init(oid: string, type: Enums.SyncEntityType, dependingOnOID: string, parentOID: string,
            precedingOID: string, issueID: number | null, issueOID: string, timestamp: string | Date,
            isErroneous: boolean, ignore: boolean)
        public Init(oid_obj: string | any, type?: Enums.SyncEntityType, dependingOnOID?: string, parentOID?: string,
            precedingOID?: string, issueID?: number | null, issueOID?: string, timestamp?: string | Date,
            isErroneous?: boolean, ignore?: boolean) {
            if (!oid_obj) {
                throw new Model.Errors.ArgumentNullError('No data passed');
            }

            let oid: string;
            if (typeof oid_obj == 'string') {
                oid = oid_obj;
            } else {
                oid = oid_obj.OID;
                type = oid_obj.Type;
                dependingOnOID = oid_obj.DependingOnOID;
                parentOID = oid_obj.ParentOID;
                precedingOID = oid_obj.PrecedingOID;
                timestamp = oid_obj.Timestamp;
                isErroneous = oid_obj.IsErroneous;
                ignore = oid_obj.Ignore;
                issueID = oid_obj.IssueID;
                issueOID = oid_obj.IssueOID;
            }

            if (!oid) {
                throw new Model.Errors.ArgumentNullError('OID is not given');
            }

            if (typeof type === 'undefined' || type === null) {
                throw new Model.Errors.ArgumentNullError('Type is not given');
            }

            if (type === Enums.SyncEntityType.SubSampleUpdate && !oid.contains('_SUBSAMPLE')) {
                oid += '_SUBSAMPLE';
            }

            if (type === Enums.SyncEntityType.SubSampleDelete && !oid.contains('_SUBSAMPLEDELETED')) {
                oid += '_SUBSAMPLEDELETED';
            }

            this.OID = oid;
            this.Type = type;
            this.Timestamp = Utils.DateTime.ToISOString(timestamp || new Date());

            if (isErroneous) {
                this.IsErroneous = true;
            }

            if (ignore) {
                this.Ignore = true;
            }

            if (!!dependingOnOID) {
                this.DependingOnOID = dependingOnOID;
            }

            if (!!parentOID) {
                this.ParentOID = parentOID;
            }

            if (!!precedingOID) {
                this.PrecedingOID = precedingOID;
            }

            // issueID merken für spätere Gruppierung nach Vorgang
            if (issueID && issueID > 0) {
                this.IssueID = issueID;
            }

            // issueOID trotz vorhandener issueID merken für spätere Sortierung
            if (issueOID) {
                this.IssueOID = issueOID;
            }
        }

        public GetDependencyOIDs(): string[] {
            const oidList = [];

            if (this.PrecedingOID) {
                oidList.push(this.PrecedingOID);
            }

            if (this.DependingOnOID) {
                oidList.push(this.DependingOnOID);
            }

            return oidList;
        }

        public AddDependency(entity: IEntityDescription): void {
            if (!entity) {
                return;
            }

            this.Dependencies = this.Dependencies || new Utils.IndexedArray('OID');

            if (!this.Dependencies.has(entity.OID)) {
                this.Dependencies.push(entity);
            }
        }

        public GetTypeString(): string | null {
            return GetTypeString(this.Type);
        }

        public GetStorageName(): Enums.DatabaseStorage | null {
            switch (this.Type) {
                case Enums.SyncEntityType.Issue:
                case Enums.SyncEntityType.IssueFile:
                case Enums.SyncEntityType.SubIssueCounter:
                case Enums.SyncEntityType.SubSampleUpdate:
                case Enums.SyncEntityType.SubSampleDelete:
                case Enums.SyncEntityType.RecordingLockState:
                    return Enums.DatabaseStorage.Issues;
                case Enums.SyncEntityType.Recorditem:
                case Enums.SyncEntityType.RecorditemDelete:
                case Enums.SyncEntityType.RecorditemAdditionalFile:
                case Enums.SyncEntityType.RecorditemValueFile:
                    return Enums.DatabaseStorage.Recorditems;
                case Enums.SyncEntityType.IssueComment:
                case Enums.SyncEntityType.IssueDeleteComment:
                case Enums.SyncEntityType.RecorditemComment:
                case Enums.SyncEntityType.RecorditemDeleteComment:
                    return Enums.DatabaseStorage.Comments;
                default:
                    return null;
            }
        }

        public abstract GetServiceMethod(): string;

        public abstract Upload(): Deferred;

        public SetOnAfterUploadHandler(onAfterUpload: OnAfterUploadFunc): EntityDescriptionBase {
            if (!(onAfterUpload instanceof Function)) {
                throw new Model.Errors.ArgumentError('onAfterUpload must be a Function');
            }

            this.OnAfterUpload = onAfterUpload;

            return this;
        }

        public SetOnUploadErrorHandler(onUploadError: OnUploadErrorFunc): EntityDescriptionBase {
            if (!(onUploadError instanceof Function)) {
                throw new Model.Errors.ArgumentError('onUploadError must be a Function');
            }

            this.OnUploadError = onUploadError;

            return this;
        }

        public SetOnEntityNotFoundErrorHandler(onEntityNotFoundError: OnEntityNotFoundErrorFunc): EntityDescriptionBase {
            if (!(onEntityNotFoundError instanceof Function)) {
                throw new Model.Errors.ArgumentError('onEntityNotFoundError must be a Function');
            }

            this.OnEntityNotFoundError = onEntityNotFoundError;

            return this;
        }

        public SetOnAfterDeleteCommentHandler(onAfterDeleteComment: OnAfterDeleteCommentFunc): EntityDescriptionBase {
            if (!(onAfterDeleteComment instanceof Function)) {
                throw new Model.Errors.ArgumentError('onAfterDeleteComment must be a Function');
            }

            this.OnAfterDeleteComment = onAfterDeleteComment;

            return this;
        }

        public SetOnDeleteCommentErrorHandler(onDeleteCommentError: OnDeleteCommentErrorFunc): EntityDescriptionBase {
            if (!(onDeleteCommentError instanceof Function)) {
                throw new Model.Errors.ArgumentError('onDeleteCommentError must be a Function');
            }

            this.OnDeleteCommentError = onDeleteCommentError;

            return this;
        }

        public SetOnCommentEntityNotFoundErrorHandler(onCommentEntityNotFoundError: OnCommentEntityNotFoundErrorFunc): EntityDescriptionBase {
            if (!(onCommentEntityNotFoundError instanceof Function)) {
                throw new Model.Errors.ArgumentError('onCommentEntityNotFoundError must be a Function');
            }

            this.OnCommentEntityNotFoundError = onCommentEntityNotFoundError;

            return this;
        }
    }

    class EntityDescription extends EntityDescriptionBase implements IWithGetServiceEntity {
        constructor(obj: any);
        constructor(skipInit: boolean);
        constructor(oid: string, type: Enums.SyncEntityType, dependingOnOID?: string, parentOID?: string, precedingOID?: string, issueOID?: string, issueID?: number, timestamp?: string | Date, isErroneous?: boolean, ignore?: boolean);
        constructor(oid_obj?: string | boolean | any, type?: Enums.SyncEntityType, dependingOnOID?: string, parentOID?: string, precedingOID?: string, issueOID?: string, issueID?: number | null, timestamp?: string | Date, isErroneous?: boolean, ignore?: boolean) {
            super();

            if (typeof oid_obj == 'boolean' &&
                oid_obj === true) {
                return;
            }

            let oid: string;
            if (typeof oid_obj == 'string') {
                oid = oid_obj;
            } else if (oid_obj) {
                oid = oid_obj.OID;
                type = oid_obj.Type;
                dependingOnOID = oid_obj.DependingOnOID;
                parentOID = oid_obj.ParentOID;
                precedingOID = oid_obj.PrecedingOID;
                timestamp = oid_obj.Timestamp;
                isErroneous = oid_obj.IsErroneous;
                ignore = oid_obj.Ignore;
                issueID = oid_obj.IssueID;
                issueOID = oid_obj.IssueOID;
            }

            this.Init(oid, type, dependingOnOID, parentOID, precedingOID, issueID, issueOID, timestamp, isErroneous, ignore);
        }

        public GetServiceMethod(): string {
            // OID kürzen bei SUBSAMPLE / LOCKSTATE
            let oid: string = this.getServiceEntityOID();

            if (this.Type === Enums.SyncEntityType.SubSampleDelete) {
                oid = this.OID.split('_')[1];
            }

            switch (this.Type) {
                case Enums.SyncEntityType.Issue:
                case Enums.SyncEntityType.SubSampleUpdate:
                case Enums.SyncEntityType.RecordingLockState:
                    return `issues/${oid}`;
                case Enums.SyncEntityType.SubSampleDelete:
                    return `issues/resubmissionitems/${oid}`;
                case Enums.SyncEntityType.IssueComment:
                case Enums.SyncEntityType.RecorditemComment:
                    return `comments/${oid}`;
                case Enums.SyncEntityType.Recorditem:
                    return `recorditems/${oid}`;
                default:
                    return null;
            }
        }

        protected getServiceEntityOID(): string {
            return this.OID.length === 36 ? this.OID : this.OID.substr(0, 36);
        }

        public GetServiceEntity(linkUploadDependency?: boolean, supportMailOption: boolean = false): Deferred {
            const deferred: Deferred = $.Deferred();
            const oid: string = this.getServiceEntityOID();
            const me = this;

            window.Database.GetSingleByKey(me.GetStorageName(), oid)
                .then(function(obj: any) {
                    if (!obj) {
                        deferred.reject();
                        return;
                    }

                    if (Utils.InArray([
                        Enums.SyncEntityType.Issue,
                        Enums.SyncEntityType.SubSampleUpdate,
                        Enums.SyncEntityType.SubSampleDelete,
                        Enums.SyncEntityType.RecordingLockState
                    ], me.Type)) {
                        obj = DAL.Issues.PrepareForSync(obj);
                    }

                    let dependencyUpdate: Deferred;

                    if (linkUploadDependency) {
                        let dependency: Utils.Synchronisation.Upload.IDependency;
                        if (me.Type === Enums.SyncEntityType.Recorditem && me.IssueID &&
                            obj.IssueID !== me.IssueID && obj.IssueOID === me.DependingOnOID) {
                            // IssueID vom SyncEntity übernehmen
                            obj.IssueID = me.IssueID;

                            dependencyUpdate = window.Database.SetInStorage(me.GetStorageName(), obj);
                        } else if ((!!me.ParentOID || !!me.DependingOnOID) &&
                            (dependency = Utils.Synchronisation.Upload.GetDependency(me.ParentOID || me.DependingOnOID)) &&
                            dependency.StorageName === Enums.DatabaseStorage.Issues &&
                            dependency.OID !== obj.PrecedingOID && dependency.OID !== obj.OID) {
                            if (me.Type === Enums.SyncEntityType.Recorditem &&
                                obj.IssueID !== dependency.ID) {
                                // IssueID vom zugehörigen Vorgang übernehmen
                                obj.IssueID = dependency.ID;

                                dependencyUpdate = window.Database.SetInStorage(me.GetStorageName(), obj);
                            } else if (Utils.InArray([
                                Enums.SyncEntityType.Issue,
                                Enums.SyncEntityType.SubSampleUpdate,
                                Enums.SyncEntityType.SubSampleDelete,
                                Enums.SyncEntityType.RecordingLockState
                            ], me.Type) && obj.ParentID !== dependency.ID) {
                                obj.ParentID = dependency.ID;

                                dependencyUpdate = window.Database.SetInStorage(me.GetStorageName(), obj);
                            }
                        }
                    }

                    switch (me.Type) {
                        case Enums.SyncEntityType.Recorditem:
                            obj = Utils.PrepareRecorditemForSync(obj);
                            break;
                        case Enums.SyncEntityType.Issue:
                            obj = DAL.Issues.PrepareForSync(obj);
                            break;
                        case Enums.SyncEntityType.IssueComment:
                        case Enums.SyncEntityType.RecorditemComment:
                            obj.EditorOID = obj.EditorOID || obj.CreatorOID;
                            if (supportMailOption) {
                                obj = new Model.Comment(obj).GetRawEntity();
                            } else {
                                obj = new Model.Comment(obj).GetPreparedForSync();
                            }
                            break;
                    }

                    if (dependencyUpdate) {
                        dependencyUpdate.always(() => {
                            deferred.resolve(obj);
                        });
                    } else {
                        deferred.resolve(obj);
                    }
                }, () => {
                    // TODO Error Handling einfügen
                    // ! Aktuell nicht möglich, da bei Datenbank-Fehlern sonst das SyncEntity !
                    // ! gelöscht wird, ohne dass Daten tatsächlich synchronisiert wurden.    !
                    //deferred.reject();
                });

            return deferred.promise();
        }

        public Upload(): Deferred {
            const me = this;

            return this.GetServiceEntity(true)
                .then(function(serviceEntity: any) {
                    const requiresDelete = me.Type === Enums.SyncEntityType.SubSampleDelete || me.Type === Enums.SyncEntityType.RecorditemDelete;
                    const httpRequest = requiresDelete ?
                        Utils.Http.Delete(me.GetServiceMethod()) :
                        Utils.Http.Put(me.GetServiceMethod(), serviceEntity);

                    httpRequest
                        .then(function(id: number) {
                            me.OnAfterUpload(serviceEntity, me.GetStorageName(), id, me);
                        }, function(xhr, status, errorText) {
                            me.OnUploadError(xhr, status, errorText, serviceEntity, me, this);
                            throw new Model.Errors.HttpError(errorText, xhr);
                        });
                })
                .fail(function() {
                    me.OnEntityNotFoundError(me);
                });
        }
    }

    export class CommentEntityDescription extends EntityDescription {
        constructor(comment: Model.Comment) {
            super(
                comment.OID,
                CommentEntityDescription.GetSyncEntityType(comment),
                comment.AssignmentOID,
                null,
                null,
                comment.IssueOID,
                comment.IssueID,
                comment.ModificationTimestamp
            );
        }

        public static GetSyncEntityType(comment: Comment): Enums.SyncEntityType {
            return comment.Type === Enums.CommentType.IssueComment ?
                Enums.SyncEntityType.IssueComment :
                Enums.SyncEntityType.RecorditemComment;
        }
    }

    export class RecordItemEntityDescription extends EntityDescription {
        public ElementOID: string;

        constructor(obj: any, _factoryBuild: boolean)
        constructor(recorditem: Model.Recorditem, dependingOnOID: string)
        constructor(rec_obj: Model.Recorditem | any, depending_factory: string | boolean) {
            super(true);

            if (depending_factory === true && typeof depending_factory == 'boolean') {
                this.Init(rec_obj);
                if (rec_obj.ElementOID) {
                    this.ElementOID = rec_obj.ElementOID;
                }
            } else {
                const recorditem = rec_obj;
                const dependingOnOID = depending_factory;
                this.Init(recorditem.OID, Enums.SyncEntityType.Recorditem, dependingOnOID || null,
                    null, recorditem.PrecedingOID, recorditem.IssueID, recorditem.IssueOID, recorditem.ModificationTimestamp, false, false);

                if (recorditem.ElementOID) {
                    this.ElementOID = recorditem.ElementOID;
                }
            }
        }
    }

    export class IssuesEntityDescription extends EntityDescription {
        constructor(issue: Model.Issues.IssueType | Model.Issues.RawIssue, dependingOnOID: string) {
            super(issue.OID,
                Enums.SyncEntityType.Issue,
                dependingOnOID,
                issue.ParentOID,
                issue.PrecedingOID,
                issue.OID,
                issue.ID,
                issue.ModificationTimestamp
            );
        }
    }

    export class SubSampleUpdateEntityDescription extends EntityDescription {
        constructor(subsampleSyncIdentifier: string, issue: Model.Issues.Issue) {
            super(subsampleSyncIdentifier,
                Enums.SyncEntityType.SubSampleUpdate,
                null,
                null,
                null,
                issue.OID,
                issue.ID,
                new Date()
            );
        }
    }

    export class SubSampleDeleteEntityDescription extends EntityDescription {
        constructor(subsampleSyncIdentifier: string, issue: Model.Issues.Issue) {
            super(subsampleSyncIdentifier,
                Enums.SyncEntityType.SubSampleDelete,
                null,
                null,
                null,
                issue.OID,
                issue.ID,
                new Date()
            );
        }
    }

    export interface IUnLockEntityDescription extends IEntityDescription {
        IssueOID: string;
        ElementOID: string
        ElementRow: number;
        IsLocked: boolean;
        DependingOnOIDs: string[];
    }

    export class UnLockEntityDescription extends EntityDescription implements IUnLockEntityDescription {
        public IssueOID: string;
        public ElementOID: string
        public ElementRow: number;
        public IsLocked: boolean;
        public DependingOnOIDs: string[];

        constructor(obj: any);
        constructor(currentIssue: Model.Issues.Issue, groupElement: Model.Elements.Element, newStateIsLocked: boolean, dependingOnOIDs?: string[]);
        constructor(obj_issue: Model.Issues.Issue | any, groupElement?: Model.Elements.Element, isLocked?: boolean, dependingOnOIDs?: string[]) {
            super(true);

            let issueOID: string;
            let oid: string = null;
            let elementOID: string;
            let elementRow: number | null;
            let issueID: number | number;
            let timestamp: Date | string;
            let isErroneous: boolean;
            let ignore: boolean;

            if (groupElement != null) {
                issueOID = obj_issue.OID;
                issueID = obj_issue.ID;
                issueOID = obj_issue.OID;
                elementOID = groupElement.OID;
                elementRow = groupElement.Row;
            } else if (obj_issue) {
                oid = obj_issue['OID'];
                issueOID = obj_issue['IssueOID'];
                elementOID = obj_issue['ElementOID'];
                elementRow = obj_issue['ElementRow'];
                isLocked = obj_issue['IsLocked'];
                timestamp = obj_issue['Timestamp'];
                dependingOnOIDs = obj_issue['DependingOnOIDs'];
                this.DependingOnOID = obj_issue['DependingOnOID'];
                isErroneous = obj_issue['IsErroneous'];
                ignore = obj_issue['Ignore'];
                issueID = obj_issue['IssueID'];
                issueOID = obj_issue['IssueOID'];
            }

            this.Init(oid || uuid(), Enums.SyncEntityType.RecordingLockState, issueOID /* TODO check*/, null, null, issueID, issueOID, timestamp, isErroneous, ignore);

            this.IssueOID = issueOID;
            this.ElementOID = elementOID;
            this.ElementRow = elementRow || 0;
            this.IsLocked = isLocked;
            this.DependingOnOIDs = dependingOnOIDs;
        }

        protected getServiceEntityOID(): string {
            return this.IssueOID;
        }

        public GetServiceEntity(linkUploadDependency?: boolean, supportMailOption: boolean = false): Deferred {
            return super.GetServiceEntity(linkUploadDependency, supportMailOption)
                .then((issue: Model.Issues.IssueType) => {

                    if (!issue || !issue.Resubmissionitems || !issue.Resubmissionitems.length) {
                        return issue;
                    }

                    const parentResubItem = issue.Resubmissionitems.filter(
                        (resubItem) => this.ElementOID == resubItem.ElementOID && (this.ElementRow || 0) == (resubItem.Row || 0)
                    );

                    if (!parentResubItem || !parentResubItem.length) {
                        return issue;
                    }

                    parentResubItem[0].IsRecordingLocked = this.IsLocked;

                    // parent resubmissionitem finden, un/lock an allen Kindelementen setzen
                    const parentResubItemOID = parentResubItem[0].OID;
                    const affectedResubmissionitems = issue.Resubmissionitems.filter(
                        (resubItem) => parentResubItemOID == resubItem.ParentOID && (this.ElementRow || 0) == (resubItem.Row || 0)
                    );

                    for (let rCnt = 0, rLen = affectedResubmissionitems.length; rCnt < rLen; rCnt++) {
                        const resubItem = affectedResubmissionitems[rCnt];
                        resubItem.IsRecordingLocked = this.IsLocked;
                    }

                    return issue;
                });
        }

        public GetDependencyOIDs(): string[] {
            if (this.DependingOnOIDs && this.DependingOnOIDs.length) {
                return super.GetDependencyOIDs()
                    .concat(this.DependingOnOIDs);
            }

            return super.GetDependencyOIDs();
        }
    }

    export class DeleteCommentEntityDescription extends EntityDescriptionBase {
        public RecorditemID: number;

        constructor(comment: Model.Comment);
        constructor(obj: any, _factoryBuild: boolean);
        constructor(oid_obj: Model.Comment | any, _factoryBuild?: boolean) {
            super();

            if (_factoryBuild) {
                this.Init(oid_obj);
            } else {
                this.Init(oid_obj.OID,
                    DeleteCommentEntityDescription.GetSyncEntityType(oid_obj),
                    oid_obj.AssignmentOID,
                    null,
                    null,
                    oid_obj.IssueID,
                    oid_obj.IssueOID,
                    oid_obj.ModificationTimestamp,
                    false,
                    false
                );
            }

            if (oid_obj.RecorditemID) {
                this.RecorditemID = oid_obj.RecorditemID;
            } else if (oid_obj.Type === Enums.CommentType.RecorditemComment ||
                oid_obj.Type === Enums.CommentType.RecorditemChangeComment) {
                this.RecorditemID = oid_obj.AssignmentID;
            }
        }

        public static GetSyncEntityType(comment: Comment): Enums.SyncEntityType {
            return comment.Type === Enums.CommentType.IssueComment ?
                Enums.SyncEntityType.IssueDeleteComment :
                Enums.SyncEntityType.RecorditemDeleteComment;
        }

        protected getServiceEntityOID(): string {
            return this.OID.length === 36 ? this.OID : this.OID.substr(0, 36);
        }

        public GetServiceMethod(): string {
            // OID kürzen bei SUBSAMPLE / LOCKSTATE
            const oid: string = this.getServiceEntityOID();

            switch (this.Type) {
                case Enums.SyncEntityType.Issue:
                case Enums.SyncEntityType.SubSampleUpdate:
                case Enums.SyncEntityType.RecordingLockState:
                    return `issues/${oid}`;
                case Enums.SyncEntityType.IssueDeleteComment:
                case Enums.SyncEntityType.RecorditemDeleteComment:
                case Enums.SyncEntityType.IssueComment:
                case Enums.SyncEntityType.RecorditemComment:
                    return `comments/${oid}`;
                case Enums.SyncEntityType.Recorditem:
                    return `recorditems/${oid}`;
                default:
                    return null;
            }
        }

        public GetServiceEntity(linkUploadDependency?: boolean, supportMailOption: boolean = false): Deferred {
            const deferred: Deferred = $.Deferred();
            const oid: string = this.getServiceEntityOID();
            const me = this;

            window.Database.GetSingleByKey(me.GetStorageName(), oid)
                .then(function(obj: any) {
                    if (obj) {
                        if (Utils.InArray([Enums.SyncEntityType.Issue, Enums.SyncEntityType.SubSampleUpdate, Enums.SyncEntityType.RecordingLockState], me.Type)) {
                            obj = DAL.Issues.PrepareForSync(obj);
                        }

                        if (linkUploadDependency) {
                            let dependency: Utils.Synchronisation.Upload.IDependency;
                            if ((!!me.ParentOID || !!me.DependingOnOID) &&
                                (dependency = Utils.Synchronisation.Upload.GetDependency(me.ParentOID || me.DependingOnOID)) &&
                                dependency.StorageName === Enums.DatabaseStorage.Issues &&
                                dependency.OID !== obj.PrecedingOID) {
                                if (me.Type === Enums.SyncEntityType.Recorditem) {
                                    obj.IssueID = dependency.ID;

                                    window.Database.SetInStorage(me.GetStorageName(), obj);
                                } else if (Utils.InArray([Enums.SyncEntityType.Issue, Enums.SyncEntityType.SubSampleUpdate, Enums.SyncEntityType.RecordingLockState], me.Type)) {
                                    obj.ParentID = dependency.ID;

                                    window.Database.SetInStorage(me.GetStorageName(), obj);
                                }
                            }
                        }

                        switch (me.Type) {
                            case Enums.SyncEntityType.Recorditem:
                                obj = Utils.PrepareRecorditemForSync(obj);
                                break;
                            case Enums.SyncEntityType.Issue:
                                obj = DAL.Issues.PrepareForSync(obj);
                                break;
                            case Enums.SyncEntityType.IssueDeleteComment:
                            case Enums.SyncEntityType.RecorditemDeleteComment:
                            case Enums.SyncEntityType.IssueComment:
                            case Enums.SyncEntityType.RecorditemComment:
                                obj.EditorOID = obj.EditorOID || obj.CreatorOID;
                                if (supportMailOption) {
                                    obj = new Model.Comment(obj).GetRawEntity();
                                } else {
                                    obj = new Model.Comment(obj).GetPreparedForSync();
                                }
                                break;
                        }

                        deferred.resolve(obj);
                    } else {
                        deferred.reject();
                    }
                });

            return deferred.promise();
        }

        public Upload(): Deferred {
            const me = this;
            return Utils.Http.Delete(me.GetServiceMethod())
                .then(function() {
                    me.OnAfterDeleteComment(me);
                }, function(xhr, status: string, errorText: string) {
                    me.OnDeleteCommentError(xhr, status, errorText, me);
                    throw new Model.Errors.HttpError(errorText, xhr);
                });
        }
    }

    export class DeleteRecorditemEntityDescription extends EntityDescriptionBase {
        public IssueID: number;

        constructor(obj: any, _factoryBuild: boolean);
        constructor(recorditem: Model.Recorditem);
        constructor(obj_rec: any | Model.Recorditem, _factoryBuild?: boolean) {
            super();

            if (_factoryBuild) {
                this.Init(obj_rec);
            } else if (obj_rec) {
                this.Init(obj_rec.OID, Enums.SyncEntityType.RecorditemDelete, null, obj_rec.IssueOID, obj_rec.PrecedingOID, obj_rec.IssueID, obj_rec.IssueOID, new Date(), false, false);
            }

            this.ID = obj_rec['ID'];
        }

        public GetServiceMethod(): string {
            return `recorditems/${this.ID}`;
        }

        public Upload(): Deferred {
            const me = this;
            return Utils.Http.Delete(this.GetServiceMethod())
                .then(function() {
                    me.OnAfterDeleteComment(me);
                }, function(xhr, status: string, errorText: string) {
                    me.OnDeleteCommentError(xhr, status, errorText, me);
                    throw new Model.Errors.HttpError(errorText, xhr);
                });
        }
    }

    export interface IFileEntityDescription extends IEntityDescription {
        Filename: string;

        SetOnAfterFileUploadHandler(onAfterFileUpload: OnAfterFileUploadFunc): IEntityDescription;
    }

    class FileEntityDescription extends EntityDescriptionBase implements IFileEntityDescription {
        public Filename: string;
        private OnAfterFileUpload: OnAfterFileUploadFunc;

        constructor(obj: any);
        constructor(filename: string, type: Enums.SyncEntityType, dependingOnOID: string, oid?: string, issueID?: number | null, issueOID?: string, timestamp?: string | Date, isErroneous?: boolean, ignore?: boolean);
        constructor(obj_filename: string | any, type?: Enums.SyncEntityType, dependingOnOID?: string, oid?: string, issueID?: number | null, issueOID?: string, timestamp?: string | Date, isErroneous?: boolean, ignore?: boolean) {
            super();

            let filename: string;
            if (typeof obj_filename == 'string') {
                filename = obj_filename;
            } else if (obj_filename) {
                filename = obj_filename.Filename;
                type = obj_filename.Type;
                dependingOnOID = obj_filename.DependingOnOID;
                oid = obj_filename.OID;
                timestamp = obj_filename.Timestamp;
                isErroneous = obj_filename.IsErroneous;
                ignore = obj_filename.Ignore;
                issueID = obj_filename.IssueID;
                issueOID = obj_filename.IssueOID;
            }

            this.Init(oid || uuid(), type, dependingOnOID, null, null, issueID, issueOID, timestamp, isErroneous, ignore);

            this.Filename = filename;
        }

        public SetOnAfterUploadHandler(onAfterUpload: OnAfterUploadFunc): EntityDescriptionBase {
            throw new Error("SetOnAfterFileUploadHandler should be used instead!");
        }

        public SetOnAfterFileUploadHandler(onAfterFileUpload: OnAfterFileUploadFunc): IEntityDescription {
            if (!(onAfterFileUpload instanceof Function)) {
                throw new Model.Errors.ArgumentError('onAfterFileUpload must be a Function');
            }

            this.OnAfterFileUpload = onAfterFileUpload;

            return this;
        }

        public GetServiceMethod(): string {
            switch (this.Type) {
                case Enums.SyncEntityType.IssueFile:
                    return `issues/${this.DependingOnOID}/files`;
                case Enums.SyncEntityType.RecorditemAdditionalFile:
                    return `recorditems/${this.DependingOnOID}/files`;
                case Enums.SyncEntityType.RecorditemValueFile:
                    return `records/images/${this.Filename}`;
                default:
                    return null;
            }
        }

        public Upload(): Deferred {
            const remoteEndpoint = Session.BaseURI + this.GetServiceMethod();
            const localFilePath = Utils.GetResourcesPath() + this.Filename;

            return Utils.Http.UploadFile(remoteEndpoint, localFilePath, this.Filename)
                .then(() => {
                    if (this.OnAfterFileUpload) {
                        this.OnAfterFileUpload(this);
                    }
                }, (response) => {
                    if (this.OnUploadError) {
                        this.OnUploadError(response, response.status, response.error, null, this, null);
                    }
                });
        };
    }

    export class RecordItemFileEntityDescription extends FileEntityDescription {
        constructor(filename: string, type: Enums.SyncEntityType, recorditem: Model.Recorditem, oid?: string, timestamp?: string | Date, isErroneous?: boolean, ignore?: boolean) {
            super(filename, type, recorditem.OID, oid, recorditem.IssueID, recorditem.IssueOID, timestamp, isErroneous, ignore);
        }
    }

    export class IssueFileEntityDescription extends FileEntityDescription {
        constructor(filename: string, type: Enums.SyncEntityType, issue: Model.Issues.IssueType | Model.Issues.RawIssue) {
            super(filename, type, issue.OID, null, issue.ID, issue.OID, issue.ModificationTimestamp);
        }
    }

    export class InspectionCounterEntityDescription extends EntityDescriptionBase {
        public AdditionalData: Dictionary<any>;

        constructor(obj: any);
        constructor(dependingOnOID: string, issue: Model.Issues.Issue | Model.Issues.RawIssue, type: Enums.SyncEntityType, additionalData: Dictionary<any>, oid: string, timestamp: string | Date, isErroneous?: boolean, ignore?: boolean);
        constructor(obj_dependingOnOID: string | any, issue?: Model.Issues.Issue | Model.Issues.RawIssue, type?: Enums.SyncEntityType, additionalData?: Dictionary<any>, oid?: string, timestamp?: string | Date, isErroneous?: boolean, ignore?: boolean) {
            super();

            let dependingOnOID: string;
            let issueID: number;
            let issueOID: string;

            if (typeof obj_dependingOnOID == 'string') {
                dependingOnOID = obj_dependingOnOID;

                if (issue) {
                    issueID = issue.ID;
                    issueOID = issue.OID;
                }
            } else if (obj_dependingOnOID) {
                dependingOnOID = obj_dependingOnOID.DependingOnOID;
                type = obj_dependingOnOID.Type;
                additionalData = obj_dependingOnOID.AdditionalData;
                oid = obj_dependingOnOID.OID;
                timestamp = obj_dependingOnOID.Timestamp;
                isErroneous = obj_dependingOnOID.IsErroneous;
                ignore = obj_dependingOnOID.Ignore;
                issueID = obj_dependingOnOID.IssueID;
                issueOID = obj_dependingOnOID.IssueOID;
            }

            this.Init(oid || uuid(), type, dependingOnOID, null, null, issueID, issueOID, timestamp, isErroneous, ignore);

            this.AdditionalData = additionalData;
        }

        public GetServiceMethod(): string {
            switch (this.Type) {
                case Enums.SyncEntityType.SubIssueCounter:
                    return `issues/${this.DependingOnOID}/additionaldata`;
                case Enums.SyncEntityType.InspectionCounter:
                    return `elements/${this.DependingOnOID}/additionaldata`;
                default:
                    return null;
            }
        }

        public Upload(): Deferred {
            return Utils.Http.Put(this.GetServiceMethod(), this.AdditionalData)
                .then((id: number) => {
                    this.OnAfterUpload(this.AdditionalData, this.GetStorageName(), id, this);
                }, function(_response, _state, _error) {
                    throw new Model.Errors.HttpError(_error, _response);
                });
        }
    }

    export class ResponseInformation {
        public EntityType: Enums.EntityType | string;
        public ResponseDate: Date;
        public LastModifiedDate: Date;
        public IsRepeating: boolean;

        constructor()
        constructor(type: Enums.EntityType | string, responseDate: Date, lastModifiedDate: Date, isRepeating?: boolean)
        constructor(type: Enums.EntityType | string, responseDate: Date, lastModifiedDate: Date, isRepeating?: boolean)
        constructor(typePath?: Enums.EntityType | string, responseDate?: Date, lastModifiedDate?: Date, isRepeating: boolean = false) {
            this.EntityType = typePath || null;
            this.ResponseDate = responseDate || null;
            this.LastModifiedDate = lastModifiedDate || null;
            this.IsRepeating = isRepeating || false;
        }

        public Clone(target?: ResponseInformation): ResponseInformation {
            target = target || new ResponseInformation();
            for (let key in this) {
                (<any>target)[key] = this[key];
            }
            return target;
        }

        public Init(data: Dictionary<any>): ResponseInformation {
            this.EntityType = data.EntityType || this.EntityType;
            this.ResponseDate = data.ResponseDate ? new Date(data.ResponseDate) : this.ResponseDate;
            this.LastModifiedDate = data.LastModifiedDate ? new Date(data.LastModifiedDate) : this.LastModifiedDate;
            this.IsRepeating = data.isRepeating || this.IsRepeating;

            return this;
        }

        public GetDBItem(): any {
            const dbItem: Dictionary<any> = {};
            for (let key in this) {
                if (!this.hasOwnProperty(key) || typeof this[key] == 'function') {
                    continue;
                }

                if (this[key] instanceof Date) {
                    dbItem[key] = Utils.DateTime.ToGMTString(<any>this[key]);
                } else {
                    dbItem[key] = this[key];
                }
            }
            return dbItem;
        }
    }

    export class ChunkedResponseInformation extends ResponseInformation {
        public Skip: number = 0;
        public Take: number = 1000;
        public RepeatCount: number = 0;
        public InitialSyncFinished: boolean = false;

        constructor(type: Enums.EntityType | string)
        constructor(type: Enums.EntityType | string, responseDate: Date, lastModifiedDate: Date, isRepeating?: boolean)
        constructor(typePath: Enums.EntityType | string, responseDate?: Date, lastModifiedDate?: Date, isRepeating: boolean = false) {
            super(typePath, responseDate, lastModifiedDate, isRepeating);
        }

        public Clone(): ResponseInformation {
            return super.Clone(new ChunkedResponseInformation(this.EntityType));
        }

        public Init(data: Dictionary<any>): ResponseInformation {
            super.Init(data);
            this.Skip = isNaN(data.Skip) ? this.Skip : Number(data.Skip);
            this.Take = isNaN(data.Take) ? this.Take : Number(data.Take);
            this.RepeatCount = isNaN(data.RepeatCount) ? 0 : data.RepeatCount;
            this.InitialSyncFinished = typeof data.InitialSyncFinished !== 'undefined' ? data.InitialSyncFinished : (!!data.LastModifiedDate);

            return this;
        }
    }

    export class ChangeResponseInformation extends ResponseInformation {
        public LastChangeID: string = '0';
        public RemainingDataItems: number = 0;
        public InitialSyncFinished: boolean = false;
        public ETag: string;

        constructor(type: Enums.EntityType | string)
        constructor(type: Enums.EntityType | string, responseDate: Date, lastModifiedDate: Date, isRepeating?: boolean)
        constructor(typePath: Enums.EntityType | string, responseDate?: Date, lastModifiedDate?: Date, isRepeating: boolean = false) {
            super(typePath, responseDate, lastModifiedDate, isRepeating);
        }

        public Clone(): ResponseInformation {
            return super.Clone(new ChunkedResponseInformation(this.EntityType));
        }

        public Init(data: Dictionary<any>): ResponseInformation {
            super.Init(data);

            this.LastChangeID = data.LastChangeID;
            this.RemainingDataItems = data.RemainingDataItems;
            this.ETag = data.ETag;

            return this;
        }
    }

    export class IssuesResponseInformation extends ChunkedResponseInformation {
        public LastSyncedIssueOIDs: Dictionary<boolean>;
        public LastModifiedDateUntil?: string;
        public FixRequest: { Skip: number, Take: number, ActivesToSkip?: number, LastSyncedIssueOIDs: Dictionary<boolean> };
        public ActivesToSkip?: number = null;

        constructor()
        constructor(responseDate: Date, lastModifiedDate: Date, lastModifiedDateUntil?: string, isRepeating?: boolean)
        constructor(responseDate?: Date, lastModifiedDate?: Date, lastModifiedDateUntil?: string, isRepeating: boolean = false) {
            super(Enums.EntityType.ISSUES, responseDate, lastModifiedDate, isRepeating);

            this.LastModifiedDateUntil = lastModifiedDateUntil || null;
        }

        public Init(data: Dictionary<any>): ResponseInformation {
            this.LastSyncedIssueOIDs = data.LastSyncedIssueOIDs || null;
            this.FixRequest = data.FixRequest || null;
            this.LastModifiedDateUntil = data.LastModifiedDateUntil || null;
            this.ActivesToSkip = data.ActivesToSkip || null;

            return super.Init(data);
        }
    }

    export class ElementsResponseInformation extends ChunkedResponseInformation {
        public NextLastModifiedDate: Date;
        public LastSyncedElementOIDs: Dictionary<boolean>;
        public FixRequest: { Skip: number, Take: number, LastSyncedElementOIDs: Dictionary<boolean> };

        constructor()
        constructor(responseDate: Date, lastModifiedDate: Date, isRepeating?: boolean)
        constructor(responseDate?: Date, lastModifiedDate?: Date, isRepeating: boolean = false) {
            super(Enums.EntityType.ELEMENTS, responseDate, lastModifiedDate, isRepeating);
        }

        public Init(data: Dictionary<any>): ResponseInformation {
            this.NextLastModifiedDate = data.NextLastModifiedDate || null;
            this.LastSyncedElementOIDs = data.LastSyncedElementOIDs || null;
            this.FixRequest = data.FixRequest || null;

            return super.Init(data);
        }
    }

    export class ResponseInformationFactory {
        public static Create(data): ResponseInformation {
            let info: ResponseInformation = null;

            switch (data.EntityType) {
                case Enums.EntityType.ISSUES:
                    info = new IssuesResponseInformation();
                    break;
                case Enums.EntityType.ELEMENTS:
                    info = new ElementsResponseInformation();
                    break;
                case Enums.EntityType.FILES:
                case Enums.EntityType.RECORDITEMS:
                    info = new ChangeResponseInformation(data.EntityType);
                    break;
                default:
                    info = new ResponseInformation();
            }

            if (info) {
                info.Init(data);
            }

            return info;
        }
    }

    export class ResponseInformationList extends Model.Serializable {
        public Information: { [type: string]: ResponseInformation };

        constructor() {
            super();
            this.Information = {};
        }

        public AddInfo(responseInfo: ResponseInformation, clone: boolean = true): void {
            if (!responseInfo) {
                return;
            }

            if (clone) {
                responseInfo = responseInfo.Clone();
            }

            this.Information[responseInfo.EntityType] = responseInfo;
        }

        public Remove(entityType: Enums.EntityType): void {
            delete this.Information[entityType];
        }

        public Clear(): void {
            this.Information = {};
        }

        public GetAll() {
            return $.map(this.Information, function(response: ResponseInformation) {
                return response.GetDBItem();
            });
        }

        public GetModifiedSinceParameter(entityType: Enums.EntityType | string): string {
            const information = this.Information[entityType];

            if (!information) {
                return '';
            }

            const getModifiedSinceString = function(date: Date) {
                const adaptedTime: number = date.getTime() - 1000;
                const adaptedModifiedSinceDate: Date = new Date(adaptedTime);

                return `modifiedsince=${Utils.DateTime.ToGMTString(adaptedModifiedSinceDate)}`;
            };

            const responseDate = information.ResponseDate ? new Date(information.ResponseDate.getTime()) : null;

            if (!information.LastModifiedDate) {
                //LastModifiedDate nicht im Header gesetzt, verwende ResponseDate - TIMEOUT
                if (responseDate) {
                    responseDate.setMilliseconds(responseDate.getMilliseconds() - Utils.Http.TIMEOUT_MS_LONG);
                    return getModifiedSinceString(responseDate);
                } else {
                    // TODO test, why no response date
                    return getModifiedSinceString(new Date(2010, 0, 1));
                }
            }

            const lastModifiedDate = new Date(information.LastModifiedDate.getTime());

            if (lastModifiedDate > responseDate) {
                // Sollte niemals größer sein aber wird sicherheitshalber abgefangen
                responseDate.setMilliseconds(responseDate.getMilliseconds() - Utils.Http.TIMEOUT_MS_LONG);
                return getModifiedSinceString(responseDate);
            }

            return getModifiedSinceString(lastModifiedDate);
        }

        public GetByType(entityType: Enums.EntityType | string): ResponseInformation {
            return this.Information[entityType];
        }
    }

    export class Tree {
        protected RawEntities: IEntityDescription[];
        protected _entities: IEntityDescription[];
        protected _entityDependencies: Dictionary<Dictionary<boolean>>;
        protected _dictEntityCache: Dictionary<IEntityDescription>;

        public get Entities() {
            if (!this._entities || this.RawEntities && this.RawEntities.length !== this._entities.length) {
                if (this.RawEntities && this.RawEntities.length) {
                    this._entities = this.prepareDependencies(this.RawEntities);
                    this.RawEntities = null;
                } else {
                    this._entities = [];
                }
            }

            return this._entities;
        }

        constructor(synchronisationEntities: Array<IEntityDescription>, takeReference: boolean) {
            if (!(synchronisationEntities || []).length) {
                throw new Model.Errors.ArgumentError('no entities given');
            }

            this.RawEntities = takeReference ? synchronisationEntities : [].concat(synchronisationEntities);
        }

        private prepareDependencies(entities: Array<IEntityDescription>): Array<IEntityDescription> {
            if (!entities || !entities.length) {
                return entities;
            }

            // Sync-Entities initial nach Zeit und Type sortieren
            entities.sort(this.compareSyncEntitiesTimeAndType);

            this._entityDependencies = {};
            this._dictEntityCache = {};

            // weitergehende Umsortierung nach Abhängigkeit
            for (const entity of entities) {
                if (!entity) {
                    continue;
                }

                this._dictEntityCache[entity.OID] = entity;
                this._entityDependencies[entity.OID] = this.getDependancies(entity);
            }

            const resultArray = []
            const remainingEntitySet = new Utils.HashSet(Object.keys(this._dictEntityCache));

            // alle Abhängigkeiten ermitteln und setzen
            for (const entity of entities) {
                if (!entity || !this._dictEntityCache.hasOwnProperty(entity.OID)) {
                    continue;
                }

                // Daten anhand Abhängigkeit in neues Array ablegen
                this.addItemsByDependency(entity, remainingEntitySet, resultArray);

                const dependingOnOIDs = this._entityDependencies[entity.OID];

                if (!dependingOnOIDs) {
                    continue;
                }

                for (const dependingOID in dependingOnOIDs) {
                    if (!dependingOnOIDs.hasOwnProperty(dependingOID)) {
                        continue;
                    }

                    const parent = this._dictEntityCache[dependingOID];
                    if (parent && parent != entity) {
                        entity.AddDependency(parent);
                    }
                }
            }

            const groupedByEntity: Dictionary<IEntityDescription[]> = {};
            // weitergehende Umsortierung nach Abhängigkeit
            for (const entity of resultArray) {
                if (!entity) {
                    continue;
                }

                // gruppieren nach Vorgang OID/ID
                if (entity.IssueID) {
                    groupedByEntity[entity.IssueID] = groupedByEntity[entity.IssueID] || [];
                    groupedByEntity[entity.IssueID].push(entity);
                }

                if (entity.IssueOID) {
                    groupedByEntity[entity.IssueOID] = groupedByEntity[entity.IssueOID] || [];
                    groupedByEntity[entity.IssueOID].push(entity);
                }
            }

            // Kommentare und Counter als vorangehende Abhängigkeit ignorieren
            const ignoreEntityTypesAsDependency = [
                Enums.SyncEntityType.IssueComment,
                Enums.SyncEntityType.RecorditemComment,
                Enums.SyncEntityType.IssueDeleteComment,
                Enums.SyncEntityType.RecorditemDeleteComment,
                Enums.SyncEntityType.SubIssueCounter,
                Enums.SyncEntityType.InspectionCounter
            ];

            // zusätzliche Abhängigkeiten (nach Erfassungsreihenfolge) innerhalb gleicher Vorgänge setzen
            for (const key in groupedByEntity) {
                if (!groupedByEntity.hasOwnProperty(key)) {
                    continue;
                }

                const groupedEntities = groupedByEntity[key];

                for (let i = 0; i < groupedEntities.length; i++) {
                    const item = groupedEntities[i];

                    if (item.Type === Enums.SyncEntityType.Issue) {
                        // Erfassungen als Abhängigkeit für Statuswechsel/Vorgangsänderung
                        // PrecedingOID bei Vorgang, alle Änderungen dazu als Abhängigkeit
                        if (item.PrecedingOID && groupedByEntity[item.PrecedingOID]) {
                            const prevItemChanges = groupedByEntity[item.PrecedingOID];

                            for (const entity of prevItemChanges) {
                                if (!Utils.InArray(ignoreEntityTypesAsDependency, entity.Type)) {
                                    item.AddDependency(entity);
                                }
                            }
                        }

                        // wenn Key eine ID ist, alle vorangehenden zusätzlich prüfen
                        if (isNaN(Number(key))) {
                            continue;
                        }

                        // alle vorangehenden Änderungen bis auf die Vorgänge und ignorierte Typen als Abhängigkeit hinzufügen
                        for (let j = i - 1; j >= 0; j--) {
                            const prevEntity = groupedEntities[j];

                            if (prevEntity.Type === Enums.SyncEntityType.Issue) {
                                break;
                            }

                            if (!Utils.InArray(ignoreEntityTypesAsDependency, prevEntity.Type)) {
                                item.AddDependency(prevEntity);
                            }
                        }
                    } else if (item.Type === Enums.SyncEntityType.SubIssueCounter || item.Type === Enums.SyncEntityType.InspectionCounter) {
                        // Abhängigkeit des SubIssue/Inspection Counter zu dem vorangehende aufbauen
                        let backCounter = i - 1;
                        let prevEntity: IEntityDescription;
                        let searchableArray = groupedEntities;

                        do {
                            prevEntity = searchableArray[backCounter];

                            // Sprung zur vorangehenden (Vorgang-)Revision
                            if (prevEntity && prevEntity.Type === Enums.SyncEntityType.Issue &&
                                prevEntity.PrecedingOID && groupedByEntity[prevEntity.PrecedingOID]) {
                                searchableArray = groupedByEntity[prevEntity.PrecedingOID];
                                backCounter = searchableArray.length - 1;

                                prevEntity = searchableArray[backCounter];
                            }

                            // alle ausser dem gleichen SyncEntityType ignorieren
                            if (prevEntity && prevEntity.Type !== item.Type) {
                                prevEntity = null;
                            }

                            backCounter--;
                        } while (backCounter >= 0 && !prevEntity); // wiederholen bis gültiges Entity gefunden wurde

                        if (prevEntity) {
                            item.AddDependency(prevEntity);
                        }
                    } else if (i > 0) {
                        // erst bei i > 0 durchführen, damit vorangehende Einträge als Abhängigkeit existieren
                        // fügt vorangehendes SyncEntity als Abhängigkeit hinzu
                        let backCounter = i - 1;
                        let prevEntity: IEntityDescription;

                        do {
                            prevEntity = groupedEntities[backCounter];

                            // Kommentare als vorangehende Abhängigkeit ignorieren
                            if (prevEntity && Utils.InArray(ignoreEntityTypesAsDependency, prevEntity.Type)) {
                                prevEntity = null;
                            }

                            backCounter--;
                        } while (backCounter >= 0 && !prevEntity); // wiederholen bis gültiges Entity gefunden wurde

                        if (prevEntity) {
                            item.AddDependency(prevEntity);
                        }
                    }
                }
            }

            this._entityDependencies = null;
            this._dictEntityCache = null;

            return resultArray;
        }

        private getDependancies(sourceEntity: IEntityDescription): Dictionary<boolean> {
            const dependanciesSet: Dictionary<boolean> = {};

            if (sourceEntity.PrecedingOID) {
                dependanciesSet[sourceEntity.PrecedingOID] = true;
            }

            if (sourceEntity.DependingOnOID) {
                dependanciesSet[sourceEntity.DependingOnOID] = true;
            }

            if (sourceEntity.DependingOnOIDs && sourceEntity.DependingOnOIDs.length) {
                for (const oid of sourceEntity.DependingOnOIDs) {
                    dependanciesSet[oid] = true;
                }
            }

            return dependanciesSet;
        }

        private compareSyncEntitiesTimeAndType(a: IEntityDescription, b: IEntityDescription): number {
            // neueste Einträge hinten anstellen
            if (a.Timestamp !== b.Timestamp) {
                const timestampResult = Utils.DateTime.Compare(a.Timestamp, b.Timestamp);
                if (timestampResult !== 0) {
                    return timestampResult;
                }
            }

            // bei gleichem Zeitstempel, Reihenfolge aufsteigend nach Typ sortieren
            let typeA = a.Type;
            let typeB = b.Type;

            if (typeA === Enums.SyncEntityType.SubSampleUpdate || typeA === Enums.SyncEntityType.RecordingLockState) {
                typeA = Enums.SyncEntityType.Issue;
            }

            if (typeB === Enums.SyncEntityType.SubSampleUpdate || typeB === Enums.SyncEntityType.RecordingLockState) {
                typeB = Enums.SyncEntityType.Issue;
            }

            if (typeA !== typeB) {
                return typeA - typeB;
            }

            // Grundsortierung, wenn andere Werte identisch sind
            return a.OID.localeCompare(b.OID, undefined, { sensitivity: 'accent' });
        }

        protected addItemsByDependency(entity: IEntityDescription, remainingEntitySet: Utils.HashSet, totalArray: IEntityDescription[]): void {
            if (!remainingEntitySet.has(entity.OID)) {
                return;
            }

            const dependencies = this._entityDependencies[entity.OID];

            for (const oid in dependencies) {
                // ignorieren wenn dependency OID = entity.OID zur vermeidung von Endlosschleife
                if (dependencies.hasOwnProperty(oid) &&
                    oid != entity.OID) {
                    const topEntity = this._dictEntityCache[oid];
                    if (topEntity) {
                        this.addItemsByDependency(topEntity, remainingEntitySet, totalArray);
                    }
                }
            }

            if (remainingEntitySet.has(entity.OID)) {
                // Eintrag aus dem Dict entfernen , weil dieser bereits eingesetzt wurde
                remainingEntitySet.delete(entity.OID);
                totalArray.push(entity);
            }
        }

        public FindEntityByIdentifier(identifier: string): IEntityDescription {
            const entitiesToSearch = this.RawEntities || this.Entities;

            if (!entitiesToSearch || !entitiesToSearch.length) {
                return null;
            }

            for (const entity of entitiesToSearch) {
                if (entity.OID === identifier) {
                    return entity;
                }
            }

            return null;
        }
    }

    export class HttpError {
        public HttpMethod: string;
        public StatusCode: number;
        public EntityType: Enums.SyncEntityType;
        public EntityOID: string;
        public Timestamp: string;
        public Message: string;

        constructor(httpMethod: string, statusCode: number, message: string, entityType: Enums.SyncEntityType, entityOID: string, timestamp?: Date) {
            httpMethod = httpMethod || "[UNKNOWN]";

            if (!httpMethod || typeof statusCode === 'undefined' || statusCode === null) {
                throw new Model.Errors.ArgumentError('httpMethod and statusCode are necessary');
            }

            this.HttpMethod = httpMethod;
            this.StatusCode = statusCode;
            this.Timestamp = Utils.DateTime.ToGMTString(!!timestamp ? timestamp : new Date());

            if (message) {
                this.Message = message;
            }

            if (entityType || entityType === Enums.SyncEntityType.Issue) {  // === 0
                this.EntityType = entityType;
            }

            if (entityOID) {
                this.EntityOID = entityOID;
            }
        }
    }

    export function BuildEntityDescription(obj: IEntityDescription): IEntityDescription {
        switch (obj.Type) {
            case Enums.SyncEntityType.Issue:
            case Enums.SyncEntityType.SubSampleUpdate:
            case Enums.SyncEntityType.SubSampleDelete:
            case Enums.SyncEntityType.IssueComment:
            case Enums.SyncEntityType.RecorditemComment:
                return new EntityDescription(obj);
            case Enums.SyncEntityType.Recorditem:
                return new RecordItemEntityDescription(obj, true);
            case Enums.SyncEntityType.IssueFile:
            case Enums.SyncEntityType.RecorditemAdditionalFile:
            case Enums.SyncEntityType.RecorditemValueFile:
                return new FileEntityDescription(obj);
            case Enums.SyncEntityType.RecordingLockState:
                return new UnLockEntityDescription(obj);
            case Enums.SyncEntityType.IssueDeleteComment:
            case Enums.SyncEntityType.RecorditemDeleteComment:
                return new DeleteCommentEntityDescription(obj, true);
            case Enums.SyncEntityType.RecorditemDelete:
                return new DeleteRecorditemEntityDescription(obj, true);
            case Enums.SyncEntityType.SubIssueCounter:
            case Enums.SyncEntityType.InspectionCounter:
                return new InspectionCounterEntityDescription(obj);
        }
    }
}
