//imports-start
/// <reference path="../definitions.d.ts"  />
/// <reference path="../templates.d.ts"  />
//imports-end

module Utils.Spinner {
    let _$spinner, _$olSpinner;
    let _$spinnerActionButton;
    let _isLocked: boolean = false;
    let _toBeHidden: boolean = false;
    let _timeoutID: number;
    let _namedLocks: Array<string> = [];
    let _stackTraces: Array<string> = [];
    let lastLock;

    const DEFAULT_TIMEOUT_HIDE = 300;

    interface IActionButton {
        Text: string;
        CssClasses: Array<string>;
        OnClick: Function;
    }

    export class SpinnerLock {
        private name: string;

        constructor(name: string) {
            this.name = name;
        }

        public GetName(): string {
            return this.name;
        }

        public Unlock(): void {
            Utils.Spinner.Unlock(this.name);
        }
    }

    export function Show(element: HTMLElement): void
    export function Show(infoText?: string, overlayOnly?: boolean, actionButton?: IActionButton, zIndex?: number): void
    export function Show(infoText_element?: string | HTMLElement, overlayOnly?: boolean, actionButton?: IActionButton, zIndex: number = 20016): void {
        resetTimeout();

        // Z-Index auf Basis anderer Elemente neu ausrichten
        if (infoText_element instanceof HTMLElement &&
            +infoText_element.style.zIndex > zIndex) {
            zIndex = +infoText_element.style.zIndex + 1;
        }

        if (!_$spinner) {
            if (!_$olSpinner) {
                _$olSpinner = Utils.Overlay.Generate('olSpinner', zIndex);
            }

            if (!overlayOnly) {
                _$spinner = $(Templates.Spinner({ zIndex: zIndex }));

                let infoText = typeof infoText_element === 'string' ? <string>infoText_element : null;

                if (!infoText) {
                    infoText = i18next.t('Spinner.DefaultText');
                }

                if (!!infoText.trim()) {
                    _$spinner.find('.text').text(infoText.trim());
                }

                $('body').append(_$spinner);

                if (actionButton && actionButton.Text && actionButton.OnClick instanceof Function) {
                    const cssClasses = actionButton.CssClasses ? actionButton.CssClasses.join(' ') : '';
                    _$spinnerActionButton = $(Templates.SpinnerActionButton({
                        Text: actionButton.Text,
                        CssClasses: cssClasses,
                        ZIndex: zIndex
                    }));

                    _$spinnerActionButton.insertAfter(_$spinner);

                    _$spinnerActionButton.find('#spinnerActionButton').on('click', actionButton.OnClick);
                }
            }
        }
    }

    export function UpdateText(infoText: string): void {
        if (_$spinner && !!infoText && !!infoText.trim()) {
            _$spinner.find('.text').text(infoText.trim());
        }
    }

    export function Hide(): void {
        resetTimeout();

        if (!_isLocked) {
            if (_$spinner) {
                _$spinner.remove();
                _$spinner = null;
            }

            if (_$olSpinner) {
                Utils.Overlay.DestroyWithTimeout(_$olSpinner, 100);
                _$olSpinner = null;
            }

            if (_$spinnerActionButton) {
                _$spinnerActionButton.remove();
                _$spinnerActionButton = null;
            }
        } else {
            console.warn("Spinner Hide() prevented by lock:", _namedLocks[_namedLocks.length - 1]);
            printStackTrace();
        }
    }

    export function HideWithTimeout(timeout: number = DEFAULT_TIMEOUT_HIDE): void {
        if (!_isLocked) {
            resetTimeout();

            if (_$spinner) {
                _$spinner.addClass('fade-out');
            }

            _toBeHidden = true;
            _timeoutID = setTimeout(Hide, timeout);
        } else {
            console.warn("Spinner HideWithTimeout() prevented by lock:", _namedLocks[_namedLocks.length - 1]);
            printStackTrace();
        }
    }

    function printStackTrace() {
        const e = new Error();
        console.log('failed hide at ', e.stack);

        if (_stackTraces.length) {
            const stack = _stackTraces[_stackTraces.length - 1];
            console.log('from lock at ', stack);
        } else if (lastLock) {
            console.log('from lock at ', lastLock);
        }
    }

    function resetTimeout() {
        if (_timeoutID && !isNaN(_timeoutID)) {
            clearTimeout(_timeoutID);

            _timeoutID = null;
            _toBeHidden = false;
        }
    }

    export function Lock(name?: string): SpinnerLock {
        if (name) {
            _namedLocks.unshift(name);
            _stackTraces.unshift(new Error().stack);
            _isLocked = true;
            return new SpinnerLock(name);
        } else {
            lastLock = new Error().stack;
        }

        _isLocked = true;
        return null;
    }

    export function Unlock(name?: string): void {
        if (name) {
            for (let i = 0; i < _namedLocks.length; i++) {
                const testName = _namedLocks[i];
                if (testName === name) {
                    _namedLocks = _namedLocks.slice(i + 1);
                    _stackTraces = _stackTraces.slice(i + 1);
                }
            }
        } else {
            lastLock = null;
        }

        if (!_namedLocks.length) {
            _isLocked = false;
        }
    }

    export function IsVisible(): boolean {
        return _$spinner && _$spinner.css('display') !== 'none' && !_toBeHidden;
    }

    export function ClearLocksOnLogout(): void {
        _namedLocks = [];
        _isLocked = false;
    }
}