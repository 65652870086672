//imports-start
//// <reference path="../definitions.d.ts"  />
//// <reference path="../app/app.session.ts"  />
//// <reference path="../model/issues/issue.ts"  />
//// <reference path="../model/properties/property.ts"  />
/// <reference path="../model/properties/root-lookup.ts"  />
/// <reference path="../model/properties/state-information.ts"  />
//imports-end

module DAL.Properties {
    let _roots: Model.Properties.RootLookup;

    let _states: Dictionary<Model.Properties.Property>;
    let _classifications: Dictionary<Model.Properties.Property>;
    let _priorities: Dictionary<Model.Properties.Property>;
    let _keywords: Dictionary<Model.Properties.Property>;
    let _categories: Dictionary<Model.Properties.Property>;
    let _units: Dictionary<Model.Properties.Property>;

    export function IsUserAllowedToChangeState(userRoles: Array<string>, stateIdentifier: string, allRequiredRecorded: boolean, mayChangeAllIssueStates: boolean): boolean {
        const currentState = _states[stateIdentifier];

        if (currentState.ChangeStateOnlyIfAllRequiredCollected && !allRequiredRecorded && !mayChangeAllIssueStates) {
            return false;
        }

        return IsUserAllowedToSeeNextState(userRoles, stateIdentifier, null, allRequiredRecorded, mayChangeAllIssueStates);
    }

    export function IsUserAllowedToSeeNextState(userRoles: Array<string>, currentStateOID: string, nextStateOID: string | null, allRequiredRecorded: boolean, mayChangeAllIssueStates: boolean): boolean {
        const currentState = _states[currentStateOID];

        if (!currentState || !(currentState.FollowStates || []).length) {
            return false;
        }

        if (!(currentState.RolesAllowChange || []).length) {
            return true;
        }

        const maySeeAllIssueStates = mayChangeAllIssueStates || Session.Settings.ShowNextStateOnUnrecorded;

        if (nextStateOID && !IsUserAllowedToSetStateByRoles(userRoles, nextStateOID, allRequiredRecorded, maySeeAllIssueStates)) {
            return false;
        }

        if ((userRoles || []).length) {
            for (let rCnt = 0, rLen = userRoles.length; rCnt < rLen; rCnt++) {
                const roleIdentifier = userRoles[rCnt];

                if (Utils.InArray(currentState.RolesAllowChange, roleIdentifier)) {
                    return true;
                }
            }
        }

        return false;
    }

    export function IsUserAllowedToSetState(userRoles: Array<string>, issue: Model.Issues.Issue, precedingStateIdentifier: string, stateIdentifier: string): boolean {
        const location = DAL.Elements.GetByOID(issue.AssignedElementOID);
        const maySetAllIssueStates = Utils.UserHasRight(Session.User.OID, Enums.Rights.ChangeOrSetIncompleteIssueState, true, location);
        const precedingState = _states[precedingStateIdentifier];
        const state = _states[stateIdentifier];

        if (!precedingState ||
            !state ||
            !(userRoles || []).length ||
            precedingStateIdentifier === stateIdentifier) {
            return false;
        }

        const allRequiredRecorded = !issue.RequiredParameterCount || (issue.CollectedRequiredParameterCount >= issue.RequiredParameterCount);

        return IsUserAllowedToSetStateByRoles(userRoles, stateIdentifier, allRequiredRecorded, maySetAllIssueStates) &&
            Utils.InArray(precedingState.FollowStates, state.OID);
    }

    export function IsUserAllowedToSetStateByRoles(userRoles: Array<string>, stateIdentifier: string, allRequiredRecorded: boolean, maySetAllIssueStates: boolean): boolean {
        if (!stateIdentifier) {
            return false;
        }

        const state = _states[stateIdentifier];

        if (!state) {
            return false;
        }

        const stateNeedsCompletedRecording = Session.LastKnownAPIVersion < 22 ?
            (state.ClosedState || state.SetStateOnlyIfAllRequiredCollected) :
            state.SetStateOnlyIfAllRequiredCollected;
        const userCanSetStatusByRecordingState = allRequiredRecorded || maySetAllIssueStates;

        if (stateNeedsCompletedRecording && !userCanSetStatusByRecordingState) {
            return false;
        }

        let roleIdentifier: string;

        // TODO prüfen ob es korrekt ist, dass ein status von jedem gesetzt werden kann,
        // wenn niemand diesem zugewiesen ist
        if ((state.RolesAllowSet || []).length) {
            for (let rCnt = 0, rLen = userRoles.length; rCnt < rLen; rCnt++) {
                roleIdentifier = userRoles[rCnt];

                if (Utils.InArray(state.RolesAllowSet, roleIdentifier)) {
                    return true;
                }
            }
        } else {
            return true;
        }

        return false;
    }

    export function GetStateModificationInfo(issue: Model.Issues.Issue): Model.Properties.StateInfo {
        if (issue == null) {
            return null;
        }

        const state = _states[issue.StateOID];
        const userRoles = Utils.GetUserRoles(issue.AssignedElementOID);
        const mayChangeAllIssueStates = Utils.UserHasRight(Session.User.OID, Enums.Rights.ChangeOrSetIncompleteIssueState, true, issue.AssignedElementOID);
        const allRequiredRecorded = !issue.RequiredParameterCount || (issue.CollectedRequiredParameterCount >= issue.RequiredParameterCount);
        const stateInfo = new Model.Properties.StateInfo();

        if (state && state.Type === Enums.PropertyType.Status) {
            stateInfo.SetStateChangeAllowed(IsUserAllowedToChangeState(userRoles, state.OID, allRequiredRecorded, mayChangeAllIssueStates));

            if ((stateInfo.StateChangeAllowed || Session.Settings.ShowNextStateOnUnrecorded) &&
                (state.StandardFollowStates || []).length) {
                for (let fsCnt = 0, fsLen = state.StandardFollowStates.length; fsCnt < fsLen; fsCnt++) {
                    const identifier = state.StandardFollowStates[fsCnt];
                    const nextState = _states[identifier];

                    if (IsUserAllowedToSeeNextState(userRoles, issue.StateOID, identifier, allRequiredRecorded, mayChangeAllIssueStates)) {
                        stateInfo.AddFollowerState(nextState);
                    }
                }
            }
        } else {
            stateInfo.SetStateChangeAllowed(true);
        }

        return stateInfo;
    }

    function prepareProperty(property: Model.Properties.Property) {
        property.Title = Utils.EscapeHTMLEntities(property.Title);

        if (!!property.Description) {
            property.Description = Utils.EscapeHTMLEntities(property.Description);
        }

        if (!!property.ActivationDescription) {
            property.ActivationDescription = Utils.EscapeHTMLEntities(property.ActivationDescription);
        }

        if (!!property.ChangeDescription) {
            property.ChangeDescription = Utils.EscapeHTMLEntities(property.ChangeDescription);
        }

        return property;
    }

    export function Store(properties: Array<Model.Properties.Property>): void {
        if (!_states) {
            _states = {};
        }

        if (!_classifications) {
            _classifications = {};
        }

        if (!_priorities) {
            _priorities = {};
        }

        if (!_keywords) {
            _keywords = {};
        }

        if (!_categories) {
            _categories = {};
        }

        if (!_units) {
            _units = {};
        }

        if (!_roots) {
            _roots = new Model.Properties.RootLookup();
        }

        if (!(properties || []).length) {
            return;
        }

        for (let pCnt = 0, pLen = properties.length; pCnt < pLen; pCnt++) {
            const property = prepareProperty(properties[pCnt]);

            if (!property.Deleted) {
                cacheProperty(property);
            } else {
                deletePropertyFromCache(property);
            }

            createRelations(_states);
            createRelations(_classifications);
            createRelations(_priorities);
            createRelations(_keywords);
            createRelations(_categories);
            createRelations(_units);
        }
    }

    function createRelations(propertyList: any): void {
        resetRelations(propertyList);

        for (let identifier in propertyList) {
            const property = propertyList[identifier];
            const parent = propertyList[property.ParentOID];

            if (!property.ParentOID || !parent) {
                continue;
            }

            property.Parent = parent;

            parent.Children = parent.Children || [];
            parent.Children.push(property);
            parent.Children.sort(Utils.SortByPosition);

            if (!!parent.ParentOID) {
                continue;
            }

            switch (property.Type) {
                case Enums.PropertyType.Status:
                    _roots.States = parent;
                    break;
                case Enums.PropertyType.Classification:
                    _roots.Classifications = parent;
                    break;
                case Enums.PropertyType.Priority:
                    _roots.Priorities = parent;
                    break;
                case Enums.PropertyType.Keyword:
                    _roots.Keywords = parent;
                    break;
                case Enums.PropertyType.ValueCategory:
                    _roots.Categories = parent;
                    break;
                case Enums.PropertyType.Unit:
                    _roots.Units = parent;
                    break;
            }
        }
    }

    function resetRelations(propertyList: any): void {
        for (let identifier in propertyList) {
            const property = propertyList[identifier];

            delete property.Parent;
            delete property.Children;
        }
    }

    function cacheProperty(property: Model.Properties.Property): void {
        switch (property.Type) {
            case Enums.PropertyType.Status:
                _states[property.OID] = property;
                break;
            case Enums.PropertyType.Classification:
                _classifications[property.OID] = property;
                break;
            case Enums.PropertyType.Priority:
                _priorities[property.OID] = property;
                break;
            case Enums.PropertyType.Keyword:
                _keywords[property.OID] = property;
                break;
            case Enums.PropertyType.ValueCategory:
                _categories[property.OID] = property;
                break;
            case Enums.PropertyType.Unit:
                _units[property.OID] = property;
                break;
        }
    }

    function deletePropertyFromCache(property: Model.Properties.Property): void {
        switch (property.Type) {
            case Enums.PropertyType.Status:
                delete _states[property.OID];
                break;
            case Enums.PropertyType.Classification:
                delete _classifications[property.OID];
                break;
            case Enums.PropertyType.Priority:
                delete _priorities[property.OID];
                break;
            case Enums.PropertyType.Keyword:
                delete _keywords[property.OID];
                break;
            case Enums.PropertyType.ValueCategory:
                delete _categories[property.OID];
                break;
            case Enums.PropertyType.Unit:
                delete _units[property.OID];
                break;
        }
    }

    export function Exists(propertyType: Enums.PropertyType, identifier: string): boolean {
        if (!identifier) {
            return false;
        }

        switch (propertyType) {
            case Enums.PropertyType.Status:
                return (_states || {}).hasOwnProperty(identifier);
            case Enums.PropertyType.Classification:
                return (_classifications || {}).hasOwnProperty(identifier);
            case Enums.PropertyType.Priority:
                return (_priorities || {}).hasOwnProperty(identifier);
            case Enums.PropertyType.Keyword:
                return (_keywords || {}).hasOwnProperty(identifier);
            case Enums.PropertyType.ValueCategory:
                return (_categories || {}).hasOwnProperty(identifier);
            case Enums.PropertyType.Unit:
                return (_units || {}).hasOwnProperty(identifier);
        }

        return false;
    }

    export function GetByOID(identifier: string): Model.Properties.Property {
        if (!identifier) {
            return;
        }

        if ((_states || {}).hasOwnProperty(identifier)) {
            return _states[identifier];
        } else if ((_classifications || {}).hasOwnProperty(identifier)) {
            return _classifications[identifier];
        } else if ((_priorities || {}).hasOwnProperty(identifier)) {
            return _priorities[identifier];
        } else if ((_keywords || {}).hasOwnProperty(identifier)) {
            return _keywords[identifier];
        } else if ((_categories || {}).hasOwnProperty(identifier)) {
            return _categories[identifier];
        } else if ((_units || {}).hasOwnProperty(identifier)) {
            return _units[identifier];
        }
    }

    export function GetByType(propertyType: Enums.PropertyType): Array<Model.Properties.Property> {
        let properties: Dictionary<Model.Properties.Property>;

        switch (propertyType) {
            case Enums.PropertyType.Status:
                properties = _states;
                break;
            case Enums.PropertyType.Classification:
                properties = _classifications;
                break;
            case Enums.PropertyType.Priority:
                properties = _priorities;
                break;
            case Enums.PropertyType.Keyword:
                properties = _keywords;
                break;
            case Enums.PropertyType.ValueCategory:
                properties = _categories;
                break;
            case Enums.PropertyType.Unit:
                properties = _units;
                break;
        }

        return $.map(properties, (property: Model.Properties.Property) => property);
    }

    export function GetRootByType(propertyType: Enums.PropertyType): Model.Properties.Property {
        const properties = GetByType(propertyType);

        if (!properties) {
            return;
        }

        for (let identifier in properties) {
            const property = properties[identifier];

            if (!property.Parent) {
                return property;
            }
        }
    }

    export function GetOrderByType(propertyType: Enums.PropertyType): Array<string> {
        const properties = GetByType(propertyType);

        if (!properties) {
            return;
        }

        const propertyRoot = $.map(properties, (property: Model.Properties.Property) => {
            if (!property.ParentOID) {
                return property;
            }
        })[0];

        if (!propertyRoot) {
            return;
        }

        const order = [];

        (function walk(property: Model.Properties.Property) {
            order.push(property.OID);

            (property.Children || []).forEach(walk);
        })(propertyRoot);

        return order;
    }

    export function IsLockedState(stateIdentifier: string): boolean {
        const state = _states ? _states[stateIdentifier] : null;

        return state && state.IsLockedState;
    }

    export function Clear(): void {
        _roots = null;
        _states = null;
        _classifications = null;
        _priorities = null;
        _keywords = null;
        _categories = null;
        _units = null;
    }

    export function GetChangesRequiringComment(oldIssueRevision: Model.Issues.Issue, newIssueRevision: Model.Issues.Issue): string[] | null {
        const affectedChanges: string[] = [];
        let requiredCommentForChanges: string[] = [];

        let form: Model.Elements.Element = null;
        if (newIssueRevision.AssignedFormRevisionOID) {
            form = DAL.Elements.GetByRevisionOID(newIssueRevision.AssignedFormRevisionOID);
        }

        if (!form && newIssueRevision.AssignedFormOID) {
            form = DAL.Elements.GetByOID(newIssueRevision.AssignedFormOID);
        }

        if (newIssueRevision.Type === Enums.IssueType.Form &&
            form.AdditionalSettings &&
            form.AdditionalSettings.MandatoryComment) {
            requiredCommentForChanges = form.AdditionalSettings.MandatoryComment.split('|');
        } else if (newIssueRevision.Type === Enums.IssueType.Form &&
            Session.Client.Settings.MandatoryFormIssueComment) {
            requiredCommentForChanges = Session.Client.Settings.MandatoryFormIssueComment.split('|');
        } else if (Session.Client.Settings.MandatoryIssueComment && (
            newIssueRevision.Type === Enums.IssueType.Task ||
            newIssueRevision.Type === Enums.IssueType.Disturbance
        )) {
            requiredCommentForChanges = Session.Client.Settings.MandatoryIssueComment.split('|');
        }

        if (requiredCommentForChanges && requiredCommentForChanges.length) {
            if (requiredCommentForChanges.indexOf('Deadline') >= 0 &&
                Utils.DateTime.Compare(oldIssueRevision.DeadlineTimestamp, newIssueRevision.DeadlineTimestamp) !== 0) {
                affectedChanges.push('Deadline');
            }

            if (requiredCommentForChanges.indexOf('Reminder') >= 0 &&
                Utils.DateTime.Compare(oldIssueRevision.RemindingTimestamp, newIssueRevision.RemindingTimestamp) !== 0) {
                affectedChanges.push('Reminder');
            }
        }

        if (!_states) {
            return affectedChanges.length ? affectedChanges : null;
        }

        const previousStateIdentifier = oldIssueRevision.StateOID;
        const newStateIdentifier = newIssueRevision.StateOID;

        if (!previousStateIdentifier ||
            previousStateIdentifier == newStateIdentifier) {
            return affectedChanges.length ? affectedChanges : null;
        }

        const previousState = _states[previousStateIdentifier];
        const newState = _states[newStateIdentifier];

        if (previousState && previousState.ChangeCommentRequired == null && newState &&
            newState.ChangeCommentRequired == null) {
            return affectedChanges.length ? affectedChanges : null;
        }

        if ((previousState && (previousState.ChangeCommentRequired === Enums.ChangeCommentRequiredType.OnChange ||
            previousState.ChangeCommentRequired === Enums.ChangeCommentRequiredType.OnChangeOrSet)) ||
            (newState && (newState.ChangeCommentRequired === Enums.ChangeCommentRequiredType.OnSet ||
                newState.ChangeCommentRequired === Enums.ChangeCommentRequiredType.OnChangeOrSet))) {
            affectedChanges.push('State')
        };

        return affectedChanges.length ? affectedChanges : null;
    }
}
